import React from "react";
import styles from "./Funds.module.sass";
import Item from "./Item";
import NoDataFound from "../../../components/NoDataFound";
import cn from "classnames";
import OrderSkeleton from "../../../components/Skeleton/OrderSkeleton";
import Dropdown from "../../../components/Dropdown";
import CustomDropdown from "../../../components/CustomDropdown";

const Funds = ({
  allOrders,
  handleLoadMore,
  pageno,
  loading,
  currencyValue,
  setCurrencyValue,
  currencyOptions,
  marketValue,
  setMarketValue,
  markets,
  currencyText,
  setCurrencyText,
  setVisibleSuccessfully,
  setCancelledItem
}) => {

  return (
    <div className={styles.wrap}>
      <div className={styles.line}>
        <div className={styles.dropdown}>
          <CustomDropdown
            value={currencyValue}
            setValue={setCurrencyValue}
            text={`Side: ${currencyText}`}
            setText={setCurrencyText}
            options={currencyOptions}
            className={styles.dropdownList}
          />
        </div>
        <div className={styles.dropdown}>
          <Dropdown
            value={`Pair: ${marketValue}`}
            setValue={setMarketValue}
            options={markets}
            className={styles.dropdownList}
          />
        </div>
      </div>
      <div className={styles.list}>
        <div className={styles.row}>
          <div className={styles.col}>ID</div>
          <div className={styles.col}>Market</div>
          <div className={styles.col}>Type</div>
          <div className={styles.col}>Rate</div>
          <div className={styles.col}>Amount</div>
          <div className={styles.col}>Value</div>
          <div className={styles.col}>Fee</div>
          <div className={styles.col}>Status</div>
          <div className={styles.col}>Action</div>
        </div>
        {loading ? <OrderSkeleton rowCount={10} colCount={9} />
          :
          allOrders?.length > 0 ?
            <>
              {allOrders?.map((x, index) => {
                return <Item
                  className={styles.item}
                  item={x}
                  key={index}
                  setVisibleSuccessfully={setVisibleSuccessfully}
                  setCancelledItem={setCancelledItem}
                />
              })}
              {
                allOrders?.length === (10 * pageno) && <div className={styles.customButton}>
                  <button className={cn("button-stroke button-small", styles.button)}
                    onClick={() => handleLoadMore()}
                  >
                    <span>Load more</span>
                  </button>
                </div>
              }
            </>
            :
            <div className={styles.btns}>
              <NoDataFound className={styles.customButton} />
            </div>
        }
      </div>
    </div>
  );
};

export default Funds;
