import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Icon from "../Icon";
import Image from "../Image";

const menu = [
  {
    title: "Exchange",
    url: localStorage.getItem("signature") !== '' ? "/exchange/BTC-EUR" : "/sign-in",
  },
  {
    title: "Markets",
    url: "/market",
  },
  {
    title: "Fees",
    url: "/fees",
  },
  {
    title: "Help Center",
    url: "/help-center",
  },
  {
    title: "Legal Center",
    url: "/legal-center",
  },
  {
    title: "Learn Crypto",
    url: "/learn-crypto",
  },
  {
    title: "Blogs & News",
    url: "https://blog.bitdenex.com",
    target: true
  },
  {
    title: "NFT",
    url: "https://nft.bitdenex.com",
    target: true
  },
  {
    title: "Bitdenex Token",
    url: "/bitdenex-token",
  },
];

const menuLegal = [
  {
    title: "Terms & Conditions",
    url: "/terms-and-conditions",
  },
  {
    title: "Privacy Policy",
    url: "/privacy-policy",
  },
  {
    title: "AML Policy",
    url: "/aml-policy",
  },
  {
    title: "KYC Policy",
    url: "/kyc-policy",
  },
  {
    title: "Location Restriction",
    url: "/location-restriction",
  },
  {
    title: "Terms For Referral Program",
    url: "/terms-and-conditions-for-referral-program",
  },
  {
    title: "Coin Listing",
    url: "/coin-listing",
  },
  {
    title: "Referral & Affiliate",
    url: "/referral-and-affiliate",
  },
  // {
  //   title: "Staking BDNX",
  //   url: "/staking",
  // },
];

const menu2 = [
  {
    title: "Buy & Sell Bitdenex Token",
    url: "/buy-bitdenex-token",
  },
  {
    title: "Buy & Sell Bitcoin",
    url: "/buy-bitcoin",
  },
  {
    title: "Buy & Sell Tron",
    url: "/buy-tron",
  },
  {
    title: "Buy & Sell Ethereum",
    url: "/buy-ethereum",
  },
  {
    title: "Buy & Sell Litecoin",
    url: "/buy-litecoin",
  },
  {
    title: "Buy & Sell Ripple XRP",
    url: "/buy-ripple",
  },
  {
    title: "Buy & Sell Stellar",
    url: "/buy-steller",
  },
  {
    title: "Buy & Sell Cardano",
    url: "/buy-cardano",
  },
  {
    title: "Buy & Sell Solana",
    url: "/buy-solana",
  },
];

// const menu3 = [
//   {
//     title: "Referral",
//     url: "/referral",
//   },
//   {
//     title: "Listing Application",
//     url: "/coin-listing",
//   },
// ];

const socials = [
  {
    title: "facebook",
    size: "16",
    url: "https://www.facebook.com/Bitdenex",
  },
  {
    title: "twitter",
    size: "18",
    url: "https://twitter.com/bitdenex",
  },
  {
    title: "instagram",
    size: "16",
    url: "https://www.instagram.com/bitdenex",
  },
  {
    title: "linkedin",
    size: "16",
    url: "https://www.linkedin.com/company/bitdenex",
  },
  // {
  //   title: "youtube",
  //   size: "16",
  //   url: "https://www.youtube.com/@bitdenex",
  // },
];

const Footer = () => {
  const [visible, setVisible] = useState(false);
  const [legal, setLegal] = useState(false);
  const [popular, setPopular] = useState(false);

  return (
    <footer className={styles.footer}>
      <div className={styles.body}>
        <div className={cn("container", styles.container)}>
          <div className={styles.col}>
            <div className={styles.logo} >
              <Link to="/">
                <img
                  className={styles.picMobile}
                  src="/images/logo-light.svg"
                  srcdark="/images/logo-dark.svg"
                  alt="Bitdenex"
                />
                <Image
                  className={styles.picDesktop}
                  src="/images/logo-light.svg"
                  srcdark="/images/logo-dark.svg"
                  alt="Bitdenex"
                />
              </Link>
              <div className={styles.contact}>
                <ul>
                  <li>Markerkant 13 72,</li>
                  <li>1314 AN Almere,</li>
                  <li>The Netherlands</li>
                  <li>Email: support@bitdenex.com</li>
                  <li>Coc: 74012940</li>
                  <li>VAT-number: NL859743676B01</li>
                </ul>
              </div>
              <div className={styles.socials}>
                {socials.map((x, index) => (
                  <a
                    className={styles.social}
                    href={x.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                    title={x.title}
                  >
                    <Icon name={x.title} size={x.size} />
                  </a>
                ))}
              </div>
            </div>
            <div className={cn(styles.item, { [styles.active]: visible })}>
              <div
                className={styles.category}
                onClick={() => setVisible(!visible)}
              >
                Overview
                <Icon name="arrow-down" size="24" />
              </div>

              <div className={styles.menu}>
                {menu.map((x, index) => {

                  if (x.target) {
                    return (<a
                      className={styles.link}
                      activeclassname={styles.active}
                      href={x.url}
                      key={index}
                      target={x.target && x.target === true ? "_blank" : ""}
                      rel={x.target && x.target === true ? "noopener noreferrer" : ""}
                    >
                      {x.title}
                    </a>)
                  } else {
                    return (

                      <NavLink
                        className={styles.link}
                        activeClassName={styles.active}
                        to={x.url}
                        key={index}
                      >
                        {x.title}
                      </NavLink>
                    )
                  }
                })}
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={cn(styles.item, { [styles.active]: legal })}>
              <div className={styles.category} onClick={() => setLegal(!legal)}>
                Legal
                <Icon name="arrow-down" size="24" />
              </div>
              <div className={styles.menu}>
                {menuLegal.map((x, index) => (
                  <NavLink
                    className={styles.link}
                    activeClassName={styles.active}
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={cn(styles.item, { [styles.active]: popular })}>
              <div
                className={styles.category}
                onClick={() => setPopular(!popular)}
              >
                popular currencies
                <Icon name="arrow-down" size="24" />
              </div>

              <div className={styles.menu}>
                {menu2.map((x, index) => (
                  <NavLink
                    className={styles.link}
                    activeClassName={styles.active}
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
          {/* <div className={styles.col}>
            <div className={cn(styles.item, { [styles.active]: popular })}>
              <div
                className={styles.category}
                onClick={() => setPopular(!popular)}
              >
                Services
                <Icon name="arrow-down" size="24" />
              </div>

              <div className={styles.menu}>
                {menu3.map((x, index) => (
                  <NavLink
                    className={styles.link}
                    activeClassName={styles.active}
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </NavLink>
                ))}
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className={styles.foot}>
        <div className={cn("container", styles.container)}>
          <div className={styles.copyright}>
            Copyright © {new Date().getFullYear()} BITDENEX. All rights reserved
          </div>
        </div>
      </div>
    </footer >
  );
};

export default Footer;
