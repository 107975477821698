import React from "react";
import cn from "classnames";
import styles from "./Currency.module.sass";
import Icon from "../../../components/Icon";
import { getDigitsAfterDecimal } from "../../../components/helper";
import { Link } from "react-router-dom";

const Currency = ({
  marketNavigation,
  marketSearch,
  setMarketSearch,
  setMarketActiveIndex,
  marketActiveIndex,
  filteredMarkets,
  handleTabClick,
  toggleFavorite,
  isFavorite,
  handleSort,
  activeColumn,
  setCurrentMarketPrice
}) => {

  return (
    <div className={styles.currency}>
      <div className={styles.nav}>
        {marketNavigation?.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === marketActiveIndex,
            })}
            onClick={() => {
              setMarketActiveIndex(index);
              handleTabClick(x);
            }}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
      <form className={styles.form} onSubmit={(e) => { e.preventDefault(); }}>
        <input
          className={styles.input}
          type="text"
          value={marketSearch}
          onChange={(e) => setMarketSearch(e.target.value)}
          name="search"
          placeholder="Search"
          required
        />
        <button className={styles.result}>
          <Icon name="search" size="20" />
        </button>
      </form>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn("sorting", { [styles.activeColumn]: activeColumn.key === "name" })}
              onClick={() => { handleSort("name", filteredMarkets) }}>
              Pair
            </div>
          </div>
          <div className={styles.col}>
            <div className={cn("sorting", { [styles.activeColumn]: activeColumn.key === "price" })}
              onClick={() => { handleSort("price", filteredMarkets) }}>
              Price
            </div>
          </div>
          <div className={styles.col}>
            <div className={cn("sorting", { [styles.activeColumn]: activeColumn.key === "dayChange" })}
              onClick={() => { handleSort("dayChange", filteredMarkets) }}>
              Change
            </div>
          </div>
        </div>
        {filteredMarkets?.map((x, index) => {
          const slug = x?.slug.replace("-", "/")?.toLowerCase()?.indexOf(marketSearch?.toLowerCase()) !== -1;
          const name = x?.name?.toLowerCase()?.indexOf(marketSearch?.toLowerCase()) !== -1;
          return marketSearch !== "" && !slug && !name ? null : (
            <Link className={styles.row} key={index} to={`/exchange/${x.slug}`}>
              <div className={styles.col} onClick={() => { setCurrentMarketPrice("") }}>
                <div className={styles.line}>
                  <button className={cn(styles.favorite)} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    toggleFavorite(x, index)
                  }}>
                    {isFavorite(x.id) ? (
                      <Icon name="star" size="12" fill="#22767d" />
                    ) : (
                      <Icon name="star-outline" size="12" />
                    )}
                  </button>
                  <div className={styles.info}>
                    <span>{x?.slug?.replace("-", "/")?.toUpperCase()}</span>
                  </div>
                </div>
              </div>
              <div className={styles.col}>
                {getDigitsAfterDecimal(x.currentMarketPrice, x.priceDecimalPrecision)}
              </div>
              <div className={styles.col}>
                {parseFloat(x.dayChange) < 0 ? (
                  <div className={styles.negative}>
                    {parseFloat(x.dayChange).toFixed(2)}
                    <small>%</small>
                  </div>
                ) : (
                  <div className={styles.positive}>
                    <small>+</small>
                    {parseFloat(x.dayChange).toFixed(2)}
                    <small>%</small>
                  </div>
                )}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Currency;
