import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import { toLocaleStringDateFormat, getDigitsAfterDecimal } from "../../../../components/helper";

const Item = ({
  item,
  setVisibleSuccessfully,
  setCancelledItem
}) => {

  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className={cn(styles.item, { [styles.active]: visible })}>
        <div className={styles.row} onClick={() => setVisible(!visible)} >
          <div className={styles.col}>
            <div className={styles.info}><small>{item?.orderId}</small></div>
          </div>
          <div className={styles.col}>{item?.slug?.toUpperCase()}
          </div>
          <div className={styles.col}>
            {(item?.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER)) && (
              <div className={styles.positive}>Buy</div>
            )}
            {(item?.orderType === parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER)) && (
              <div className={styles.negative}>Sell</div>
            )}
          </div>
          {/* <div className={styles.col}>
            {(item?.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER)) && (<div className={styles.info}>Limit</div>)}
            {(item?.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER)) && (<div className={styles.info}>Market</div>)}
          </div> */}

          <div className={styles.col}>
            {item.orderType == process.env.REACT_APP_BUY_LIMIT_ORDER || item.orderType == process.env.REACT_APP_BUY_MARKET_ORDER ? (
              <div className={styles.positive}>{getDigitsAfterDecimal(item.price, item.priceDecimalPrecision)} <small>  {item?.slug.split("-")[1].toUpperCase()} </small> </div>
            ) :
              (<div className={styles.negative}>{getDigitsAfterDecimal(item.price, item.priceDecimalPrecision)} <small>  {item?.slug.split("-")[1].toUpperCase()} </small> </div>)}
          </div>

          <div className={styles.col}>
            {item.orderType == process.env.REACT_APP_BUY_LIMIT_ORDER || item.orderType == process.env.REACT_APP_BUY_MARKET_ORDER ? (
              <div className={styles.positive}>{getDigitsAfterDecimal(item.crypto_amount, item.amountDecimalPrecision)} <small> {item?.slug.split("-")[0].toUpperCase()} {item.crypto_exe_amount > 0 ? "(P.filled =" + item.crypto_exe_amount + ")" : null} </small>  </div>
            ) :
              (<div className={styles.negative}>{getDigitsAfterDecimal(item.crypto_amount, item.amountDecimalPrecision)} <small> {item?.slug.split("-")[0].toUpperCase()} {item.crypto_exe_amount > 0 ? "(P.filled =" + item.crypto_exe_amount + ")" : null} </small>  </div>)}
          </div>
          <div className={styles.col}>
            {item.orderType == process.env.REACT_APP_BUY_LIMIT_ORDER || item.orderType == process.env.REACT_APP_BUY_MARKET_ORDER ? (
              <div className={styles.positive}>{getDigitsAfterDecimal(item.fiat_amount, item.priceDecimalPrecision)} <small>  {item?.slug.split("-")[1].toUpperCase()} {item.fiat_exe_amount > 0 ? "(P.filled =" + item.fiat_exe_amount + ")" : null} </small>   </div>
            ) :
              (<div className={styles.negative}>{getDigitsAfterDecimal(item.fiat_amount, item.priceDecimalPrecision)} <small> {item?.slug.split("-")[1].toUpperCase()} {item.fiat_exe_amount > 0 ? "(P.filled =" + item.fiat_exe_amount + ")" : null} </small>   </div>)}
          </div>
          <div className={styles.col}>
            {/* {getDigitsAfterDecimal(item?.fiatFee, item?.priceDecimalPrecision)} <span className={styles.conv}> {item?.currency?.toUpperCase()}  </span> */}
            {(item?.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER)) ?
              <>
                {getDigitsAfterDecimal(item?.fiatFee, item?.priceDecimalPrecision, true)} <small> {item?.currency?.toUpperCase()}  </small>
              </>
              :
              (item?.orderType === parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER)) ?
                <>
                  {getDigitsAfterDecimal(item?.cryptoFee, item?.amountDecimalPrecision)} <small> {item?.coin?.toUpperCase()}  </small>
                </>
                :
                null}
          </div>
          <div className={styles.col}>
            <div className={styles.info}> {item.exeCrypto > 0 ? "(P.filled =" + item.exeCrypto + ")"
              :
              parseInt(item?.orderType) === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER) ||
                parseInt(item?.orderType) === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER) ? "In-process" :
                'Open'
            }
            </div>
          </div>
          <div className={styles.col}>
            <button
              disabled={parseInt(item?.orderType) === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER) ||
                parseInt(item?.orderType) === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER)
              }
              className={cn("button-small button-red", styles.button)}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setVisibleSuccessfully(true);
                setCancelledItem(item);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
        <div className={styles.btns}>
          <div className={styles.col}>
            <div className={styles.info}> <span> Date: {toLocaleStringDateFormat(item.updatedAt)} </span></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Item;
