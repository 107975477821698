import React, { useState } from "react";
import cn from "classnames";
import styles from "./Deposit.module.sass";
import Icon from "../Icon";
import Dropdown from "../Dropdown";
import { addNotification } from "../Notification";
import QrCode from "../QrCode";
import DepositSkelton from "../Skeleton/BankSkeleton";
import { twoDigitsAfterDecimal } from "../helper";

const Deposit = ({
  state,
  imageUrl,
  cryptoAddressGenerater,
  depositLoadting,
  networkSlug,
  fireBlockId,
  loader
}) => {
  const [token, setToken] = useState("Please select network");
  const [indexId, setIndexId] = useState();

  const supportedNetworks = process.env.REACT_APP_NETWORKS?.split(",");

  return (
    <div className={styles.deposit}>
      <div className={styles.body}>
        <div className={styles.item}>
          <div className={cn("h5", styles.title)}>
            Deposit {state.symbol.toUpperCase()}
          </div>
          <div className={styles.icon} >
            <img src={imageUrl + state.image} alt="Currency" className={styles.image} />
          </div>
          <div className={styles.wrap}>
            <div className={styles.category}>Total balance</div>
            <div className={styles.details}>
              <div className={styles.currency}>{twoDigitsAfterDecimal(state.balance, state.symbol)} <small>{state.symbol.toUpperCase()}</small></div>
            </div>
          </div>
          <div className={styles.stage}>Address</div>
          <div className={styles.content}>
            Only send to this address. Sending any other asset to this address may result in the loss of your deposit!
          </div>

          {networkSlug && networkSlug.length > 0 &&
            (
              <div className={styles.field}>
                {loader ?
                  <DepositSkelton count={2} height={30} />
                  :
                  <>
                    <Dropdown
                      className={styles.dropdown}
                      label="Select network"
                      options={networkSlug}
                      value={token}
                      setValue={setToken}
                      setIndex={setIndexId}
                    />
                    <br />
                  </>
                }
              </div>
            )
          }
          {supportedNetworks?.length > 0 && token !== "Please select network" && (
            <>
              {supportedNetworks?.includes(token) && (
                <>
                  {state?.address && state?.address[token] ? (
                    <>
                      <div className={styles.code}>
                        <small className={styles.address} title={state.address[token]}>
                          {state.address[token].substring(0, 45)} ...
                        </small>
                        <button
                          className={styles.copy}
                          onClick={(e) => {
                            e.preventDefault();
                            navigator.clipboard.writeText(state.address[token]).then(() => {
                              addNotification({
                                title: 'Success',
                                message: 'Address copied successfully',
                                type: 'success'
                              });
                            });
                          }}
                        >
                          <Icon name="copy" size="24" />
                        </button>
                      </div>
                      <div className={styles.preview}>
                        <QrCode value={state.address[token]} width={40} height={40} />
                      </div>
                    </>
                  ) : (
                    <div className={styles.preview}>
                      <button onClick={() => cryptoAddressGenerater(state.coinId, fireBlockId[indexId])}>
                        {depositLoadting ? (
                          <div className={styles.buttonText}> Loading...</div>
                        ) : (
                          <div className={styles.buttonText}> Generate Address For {state.symbol.toUpperCase()} </div>
                        )}
                      </button>
                    </div>
                  )}
                </>
              )}
            </>
          )}

          {networkSlug && networkSlug.length === 0 && state.stableCoin !== parseInt(process.env.REACT_APP_UNSTABLE_COIN) && (
            <>
              {state.address && Object.keys(state.address).length === 1 ?
                <>
                  <div className={styles.code}>
                    <small className={styles.address} title={state.address?.address}> {state.address?.address.substring(0, 45)} ... </small>
                    <button className={styles.copy} onClick={(e) => {
                      e.preventDefault();
                      navigator.clipboard.writeText(state.address?.address).then(() => {
                        addNotification({
                          title: 'Success',
                          message: 'Address copied successfully',
                          type: 'success'
                        })
                      })
                    }}>
                      <Icon name="copy" size="24" />
                    </button>
                  </div>
                  {
                    state?.tag &&
                    <>
                      <div className={styles.stage}>Memo/Tag</div>
                      <div className={styles.code}>
                        <small className={styles.address}>{state.tag}</small>
                        <button className={styles.copy} onClick={(e) => {
                          e.preventDefault();
                          navigator.clipboard.writeText(state.tag).then(() => {
                            addNotification({
                              title: 'Success',
                              message: 'Tag/Memo copied successfully',
                              type: 'success'
                            })
                          })
                        }}>
                          <Icon name="copy" size="24" />
                        </button>
                      </div>
                      <div className={styles.contentMessage}>
                        Both Address and Memo/Tag is required, or you will lose your coins.
                      </div>
                    </>
                  }
                  <div className={styles.preview}>
                    <QrCode value={state.address?.address} width={40} height={40} />
                  </div>
                </>
                :
                <div className={styles.preview}>
                  <button onClick={() => cryptoAddressGenerater(state.coinId, fireBlockId[indexId])}>
                    {depositLoadting ?
                      <div className={styles.buttonText}> Loading...</div>
                      :
                      <div className={styles.buttonText}> Generate Address For  {state.symbol.toUpperCase()} </div>
                    }
                  </button>
                </div>
              }
            </>
          )}
          <div className={styles.note}>Be sure that the address is related to the coin/token that you are depositing.</div>
        </div>
      </div>
    </div>
  );
};

export default Deposit;
