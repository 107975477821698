import React, { useState } from "react";
import cn from "classnames";
import styles from "./Table.module.sass";
import Dropdown from "../../../components/Dropdown";
import { getDigitsAfterDecimal, toLocaleStringDateFormat } from "../../../components/helper";

const navigation = ["My Open orders", "Market trades"];

const Table = ({
  orderData,
  setCancelOrderDetails,
  setCancelModalVisible
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [sorting, setSorting] = useState(navigation[0]);

  return (
    <div className={styles.inner}>
      <Dropdown
        className={styles.dropdown}
        value={sorting}
        setValue={setSorting}
        options={navigation}
      />
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Time</div>
          <div className={styles.col}>Type</div>
          <div className={styles.col}>Order ID</div>
          <div className={styles.col}>Market</div>
          <div className={styles.col}>Price</div>
          <div className={styles.col}>Amount</div>
          <div className={styles.col}>Total</div>
          {activeIndex === 0 &&
            <>
              <div className={styles.col}>Fee</div>
              <div className={styles.col}>Action</div>
            </>
          }
          {activeIndex === 1 &&
            <div className={styles.col}>Status</div>
          }
        </div>
        {
          activeIndex === 0 ?
            orderData?.open_order?.slice(0, 7)?.map((x, index) => (
              <div className={styles.row} key={index}>
                <div className={styles.col}>{toLocaleStringDateFormat(x.updatedAt)}</div>
                <div className={styles.col}>
                  {parseInt(x?.orderType) === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || parseInt(x?.orderType) === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER)
                    ?
                    <div className={styles.positive}>Buy</div>
                    :
                    <div className={styles.negative}>Sell</div>
                  }
                </div>
                <div className={styles.col}>{x?.orderId}</div>
                <div className={styles.col}>{x?.slug?.toUpperCase()}</div>
                <div className={styles.col}>
                  {parseInt(x?.orderType) === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || parseInt(x.orderType) === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER) ? (
                    <div className={styles.positive}>
                      {getDigitsAfterDecimal(x?.price, orderData?.current_market_details?.[0]?.priceDecimalPrecision)}
                      <small className={styles.conv}> {x?.slug?.split("-")?.[1]?.toUpperCase()}</small>
                    </div>
                  ) : (
                    <div className={styles.negative}>
                      {getDigitsAfterDecimal(x?.price, orderData?.current_market_details?.[0]?.priceDecimalPrecision)}
                      <small className={styles.conv}> {x?.slug?.split("-")?.[1]?.toUpperCase()}</small>
                    </div>
                  )}
                </div>
                <div className={styles.col}>
                  {parseInt(x?.orderType) === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || parseInt(x.orderType) === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER) ? (
                    <div className={styles.positive}>
                      {getDigitsAfterDecimal(x.crypto_amount, orderData?.current_market_details?.[0]?.amountDecimalPrecision)}
                      <small className={styles.conv}> {x?.slug?.split("-")?.[0]?.toUpperCase()}</small>
                      {x.crypto_exe_amount > 0 && (<div className={styles.conv}> (P.filled ≈ {getDigitsAfterDecimal(x.crypto_exe_amount, orderData?.current_market_details?.[0]?.amountDecimalPrecision)}) </div>)}</div>
                  ) : (
                    <div className={styles.negative}>
                      {getDigitsAfterDecimal(x.crypto_amount, orderData?.current_market_details?.[0]?.amountDecimalPrecision)}
                      <small className={styles.conv}> {x?.slug?.split("-")?.[0]?.toUpperCase()}</small>
                      {x.crypto_exe_amount > 0 && (<div className={styles.conv}> (P.filled ≈ {getDigitsAfterDecimal(x.crypto_exe_amount, orderData?.current_market_details?.[0]?.amountDecimalPrecision)}) </div>)}</div>)}
                </div>
                <div className={styles.col}>
                  {parseInt(x?.orderType) === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || parseInt(x.orderType) === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER) ? (
                    <div className={styles.positive}>{getDigitsAfterDecimal(x.fiat_amount, orderData?.current_market_details?.[0]?.priceDecimalPrecision)}
                      <small className={styles.conv}> {x?.slug?.split("-")?.[1]?.toUpperCase()}</small>
                      {x.fiat_exe_amount > 0 && (<div className={styles.conv}> (P.filled ≈  {getDigitsAfterDecimal(x.fiat_exe_amount, orderData?.current_market_details?.[0]?.priceDecimalPrecision)}) </div>)}</div>
                  ) : (
                    <div className={styles.negative}>{getDigitsAfterDecimal(x.fiat_amount, orderData?.current_market_details?.[0]?.priceDecimalPrecision)}
                      <span className={styles.conv}> {x?.slug?.split("-")?.[1]?.toUpperCase()}</span>
                      {x.fiat_exe_amount > 0 && (<div className={styles.conv}> (P.filled ≈  {getDigitsAfterDecimal(x.fiat_exe_amount, orderData?.current_market_details?.[0]?.priceDecimalPrecision)}) </div>)}</div>
                  )}
                </div>
                <div className={styles.col}>
                  {parseInt(x?.orderType) === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || parseInt(x.orderType) === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER) ?
                    <>
                      {getDigitsAfterDecimal(x?.fiatFee, orderData?.current_market_details?.[0]?.priceDecimalPrecision, true)}
                      <small className={styles.conv}> {x?.slug?.split("-")?.[1]?.toUpperCase()}</small>
                    </>
                    :
                    <>
                      {getDigitsAfterDecimal(x?.cryptoFee, orderData?.current_market_details?.[0]?.amountDecimalPrecision)}
                      <small className={styles.conv}> {x?.slug?.split("-")?.[0]?.toUpperCase()}</small>
                    </>
                  }
                </div>
                <div className={styles.col}>
                  <button className={cn("button-small button-red", styles.button)}
                    disabled={parseInt(x?.orderType) === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER) ||
                      parseInt(x?.orderType) === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER)
                    }
                    onClick={() => {
                      setCancelOrderDetails(x);
                      setCancelModalVisible(true);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ))
            :
            orderData?.market_trades?.slice(0, 7)?.map((x, index) => (
              <div className={styles.row} key={index}>
                <div className={styles.col}>{toLocaleStringDateFormat(x.updatedAt)}</div>
                <div className={styles.col}>
                  {parseInt(x?.orderType) === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || parseInt(x?.orderType) === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER)
                    ?
                    <div className={styles.positive}>Buy</div>
                    :
                    <div className={styles.negative}>Sell</div>
                  }
                </div>
                <div className={styles.col}>{x?.orderId}</div>
                <div className={styles.col}>{x?.slug?.toUpperCase()}</div>
                <div className={styles.col}>
                  {parseInt(x?.orderType) === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || parseInt(x.orderType) == parseInt(process.env.REACT_APP_BUY_MARKET_ORDER) ? (
                    <div className={styles.positive}>
                      {getDigitsAfterDecimal(x?.price, orderData?.current_market_details?.[0]?.priceDecimalPrecision)}
                      <small className={styles.conv}> {x?.slug?.split("-")?.[1]?.toUpperCase()}</small>
                    </div>
                  ) : (
                    <div className={styles.negative}>
                      {getDigitsAfterDecimal(x?.price, orderData?.current_market_details?.[0]?.priceDecimalPrecision)}
                      <small className={styles.conv}> {x?.slug?.split("-")?.[1]?.toUpperCase()}</small>
                    </div>
                  )}
                </div>
                <div className={styles.col}>
                  {parseInt(x?.orderType) === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || parseInt(x.orderType) == parseInt(process.env.REACT_APP_BUY_MARKET_ORDER) ? (
                    <div className={styles.positive}>
                      {getDigitsAfterDecimal(x?.crypto_amount, orderData?.current_market_details?.[0]?.amountDecimalPrecision)}
                      <small className={styles.conv}> {x?.slug?.split("-")?.[0]?.toUpperCase()}</small>
                    </div>
                  ) : (
                    <div className={styles.negative}>
                      {getDigitsAfterDecimal(x?.crypto_amount, orderData?.current_market_details?.[0]?.amountDecimalPrecision)}
                      <small className={styles.conv}> {x?.slug?.split("-")?.[0]?.toUpperCase()}</small>
                    </div>
                  )}
                </div>
                <div className={styles.col}>
                  {parseInt(x?.orderType) === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || parseInt(x.orderType) == parseInt(process.env.REACT_APP_BUY_MARKET_ORDER) ? (
                    <div className={styles.positive}>
                      {getDigitsAfterDecimal(x?.fiat_amount, orderData?.current_market_details?.[0]?.priceDecimalPrecision)}
                      <small className={styles.conv}> {x?.slug?.split("-")?.[1]?.toUpperCase()}</small>
                    </div>
                  ) : (
                    <div className={styles.negative}>
                      {getDigitsAfterDecimal(x?.fiat_amount, orderData?.current_market_details?.[0]?.priceDecimalPrecision)}
                      <small className={styles.conv}> {x?.slug?.split("-")?.[1]?.toUpperCase()}</small>
                    </div>
                  )}
                </div>
                <div className={styles.col}>Filled</div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default Table;
