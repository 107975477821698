import React, { useEffect, useState, useCallback } from "react";
import cn from "classnames";
import styles from "./Exchange.module.sass";
import Main from "./Main";
import Balance from "./Balance";
import Currency from "./Currency";
import Table from "./Table";
import Actions from "./Actions";
import Charts from "./Charts";
import { useMediaQuery } from "react-responsive";
import requestHandler from "../../actions/httpClient";
import { useDispatch, useSelector } from "react-redux";
// import { socket } from "../../socket";
import { userStatusCreator } from "../../actions/getUserStatus";
import { socketDataReceived } from "../../actions/markets/index";
import { useParams } from "react-router";
import MarketScroller from "../../components/MarketScroller";
import { addNotification } from "../../components/Notification";
import LoaderScreen from "../../components/LoaderScreen";
import Modal from "../../components/Modal";
import CancelOrder from "./CancelOrder";
import { io } from "socket.io-client";

const navigation = ["Chart", "Order books", "Trades"];
const OrderTypeNavigation = ["Limit", "Market"];
const marketNavigation = ["All", "Favorites"];

const Exchange = () => {
    const { slug } = useParams();
    let signature = localStorage.getItem("signature");
    const [activeIndex, setActiveIndex] = useState(0);
    const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });
    const [loading, setLoading] = useState(false);
    const [orderData, setOrderData] = useState([]);
    const { socketMarketData } = useSelector((state) => state.markets);
    const dispatch = useDispatch();
    const [selectedMarket, setSelectedMarket] = useState([]);
    const [filteredMarkets, setFilteredMarkets] = useState([]);
    const [marketSearch, setMarketSearch] = useState("");
    const [marketActiveIndex, setMarketActiveIndex] = useState(0);
    const [activeMarketTab, setActiveMarketTab] = useState("All");
    const [activeColumn, setActiveColumn] = useState({ key: "", value: false });
    const { userStatus } = useSelector((state) => { return state.getUserStatus });
    const [cancelModalVisible, setCancelModalVisible] = useState(false);
    const [cancelOrderDetails, setCancelOrderDetails] = useState([]);
    const [buyOrdersData, setBuyOrdersData] = useState([]);
    const [sellOrdersData, setSellOrdersData] = useState([]);
    const [favoriteMarkets, setFavoriteMarkets] = useState(userStatus?.userFavMarkets?.length > 0 ? userStatus?.userFavMarkets : []);
    const [order, setOrder] = useState({ column: null, order: 'ASC' });
    const [currentMarketPrice, setCurrentMarketPrice] = useState("");
    const [previousPrice, setPreviousPrice] = useState(null);
    const [priceTrend, setPriceTrend] = useState(null);
    const priceColor = priceTrend === 'up' ? 'top' : priceTrend === 'down' ? 'bottom' : "top";

    // useEffect to set the user data in the store
    useEffect(() => {
        if (userStatus?.length === 0) {
            dispatch(userStatusCreator());
        }
    }, [userStatus]);

    // useEffect to set the markets 
    useEffect(() => {
        if (socketMarketData?.length && activeMarketTab === "All" && activeColumn.key === "") {
            const sortedMarkets = socketMarketData?.[0]?.markets?.sort((a, b) => a.market_position - b.market_position);
            setFilteredMarkets(sortedMarkets);
        }
    }, [socketMarketData]);

    // useEffect for sockets data (Market socket, Order Book)
    useEffect(() => {
        if (slug) {
            let timeoutId = null;
            const socket = io(process.env.REACT_APP_SOCKET_URL, {
                autoConnect: false,
                transports: ['websocket']
            });
            let marketSocket = "new_europe_markets_web"
            let orderBook = `new_orderBook_${slug.toLowerCase()}`
            let orderBookPrice = `new_orderBookPrice_${slug.toLowerCase()}`
            const marketData = "europe_markets";
            socket.connect();

            socket.on('connect', () => {
                socket.emit("marketData", marketData);
                socket.emit("subscribe", slug?.toLowerCase());
            });

            socket.on(marketSocket, (data) => {
                dispatch(socketDataReceived(data));
            });

            socket.on(orderBook, function (order) {
                let buyMaxVol = calculateMaxVolumeOfOrderBook(order, "buy");
                let sellMaxVol = calculateMaxVolumeOfOrderBook(order, "sell");
                setBuyOrdersData(createOrderbook(order?.buy, buyMaxVol));
                setSellOrdersData(createOrderbook(order?.sell, sellMaxVol));
            });

            socket.on(orderBookPrice, function (order) {
                const newPrice = parseFloat(order?.currentMarketPrice);

                if (parseFloat(previousPrice) !== null) {
                    if (parseFloat(newPrice) >= parseFloat(previousPrice)) {
                        setPriceTrend('up');
                    } else if (parseFloat(newPrice) < parseFloat(previousPrice)) {
                        setPriceTrend('down');
                    }
                }

                setPreviousPrice(newPrice);
                setCurrentMarketPrice(newPrice);
                timeoutId = setTimeout(() => {
                    socket.emit("marketData", marketData);
                    socket.emit("subscribe", slug?.toLowerCase());
                }, parseInt(process.env.REACT_APP_ORDERBOOK_EMIT_TIME_INERTVAL));
            });

            return (() => {
                clearTimeout(timeoutId);
                socket.disconnect();
            });
        }
    }, [slug]);

    // useEffect to call the openOrder function
    useEffect(() => {
        getOpenOrders(slug);
    }, [slug]);

    // useEffect to set the selected market data
    useEffect(() => {
        if (slug && socketMarketData?.[0]?.markets) {
            const marketDetails = socketMarketData[0].markets.find((market) => market.slug === slug);
            if (marketDetails && marketDetails.slug !== selectedMarket.slug) {
                setSelectedMarket(marketDetails);
            }
        }
    }, [slug, socketMarketData]);

    // getOpenOrder api
    const getOpenOrders = async (slug) => {
        setLoading(true);
        let data = {
            market_slug: slug,
            signature: signature,
        };
        try {
            const getOpenOrderPayload = await requestHandler("getOpenOrders", "post", data, "jwt_token");
            setLoading(false);
            setOrderData(getOpenOrderPayload?.data?.data);
            let buyMaxVol = calculateMaxVolumeOfOrderBook(getOpenOrderPayload?.data?.data, "buy");
            let sellMaxVol = calculateMaxVolumeOfOrderBook(getOpenOrderPayload?.data?.data, "sell");
            setBuyOrdersData(createOrderbook(getOpenOrderPayload.data.data.buy, buyMaxVol));
            setSellOrdersData(createOrderbook(getOpenOrderPayload.data.data.sell, sellMaxVol));
        }
        catch (e) {
            setLoading(false);
            // console.log("error", e);
        };
    };

    // callback to render the charts component for once and if the slug changes
    const renderCharts = useCallback(() => {
        return <Charts
            slug={slug}
            orderData={orderData}
        />
    }, [slug]);

    // newOrder functionality
    const newOrderHandler = async (marketId, orderType, limitPrice, buyAmount, sellAmount) => {
        setLoading(true);
        let data = {
            marketId: marketId,
            orderType: orderType,
            fiat_price: limitPrice,
            fiat_spend: buyAmount,
            crypto_spend: sellAmount,
            signature: signature
        };
        // console.log("data", data);
        try {
            const newOrderPayload = await requestHandler("new_order", "post", data, "jwt_token");
            // console.log("newOrderPayload", newOrderPayload);
            setLoading(false);
            if (newOrderPayload?.status === 200) {
                addNotification({
                    title: "Success",
                    message: newOrderPayload?.data?.message?.[0]?.msg,
                    type: "success"
                });
                await getOpenOrders(slug);
            }
        }
        catch (e) {
            setLoading(false);
            // console.log("error", e);
        };
    };

    // cancelOrder functionality
    const cancelOrderHandler = async (orderId) => {
        setLoading(true);
        let data = {
            signature: signature,
            orderId: orderId,
        };
        try {
            const cancelOrderPayload = await requestHandler("cancelOrder", "post", data, "jwt_token");
            if (cancelOrderPayload?.status === 200) {
                addNotification({
                    title: "Success",
                    message: cancelOrderPayload?.data?.message?.[0]?.msg,
                    type: "success",
                });
                setLoading(false);
                await getOpenOrders(slug);
                setCancelModalVisible(false);
            }
        }
        catch (e) {
            setLoading(false);
            addNotification({
                title: "Error",
                message: "Request Declined",
                type: "danger",
            });
            setCancelModalVisible(false);
        }
    };

    const createOrderbook = (orders, maxVol) => {
        let createOrders = [];
        let tempData = [];
        for (let order of orders) {
            if (maxVol) {
                let newNormalizedVolume = Number(order?.remfiat) / Number(maxVol);
                order.normalizedVolume = newNormalizedVolume;
            }
            if (tempData.indexOf(parseFloat(order.fiatPrice)) < 0) {
                createOrders.push(order)
                tempData.push(parseFloat(order.fiatPrice));
            } else {
                let index = tempData.indexOf(parseFloat(order.fiatPrice));
                createOrders[index].remcrypto = parseFloat(order.remcrypto) + parseFloat(createOrders[index].remcrypto);
                createOrders[index].remfiat = parseFloat(order.remfiat) + parseFloat(createOrders[index].remfiat);
            }
        }
        return createOrders;
    };

    const handleTabClick = (tab) => {
        setActiveMarketTab(tab);
        filterData(socketMarketData, tab);
    };

    const isFavorite = (marketId) => {
        return favoriteMarkets?.includes(marketId);
    };

    const toggleFavorite = async (item) => {
        setLoading(true);
        let data = {
            signature: localStorage.getItem("signature"),
            marketId: item?.id
        };
        try {
            const favoritePayload = await requestHandler("addRemoveFavMarkets", "post", data, "jwt_token");
            setLoading(false);
            if (favoritePayload?.status === 200) {
                dispatch(userStatusCreator());
                setFavoriteMarkets(prevFavorites => {
                    const isFavorite = prevFavorites?.includes(item.id);
                    if (isFavorite) {
                        const updatedFavorites = prevFavorites?.filter(id => id !== item.id);
                        if (activeMarketTab === "Favorites") {
                            const updatedFilteredData = socketMarketData?.[0]?.markets?.filter(dataItem => updatedFavorites?.includes(dataItem.id));
                            setFilteredMarkets(updatedFilteredData);
                        }
                        return updatedFavorites;
                    }
                    else {
                        return [...prevFavorites, item?.id];
                    }
                });
            }
        }
        catch (e) {
            setLoading(false);
        }
    };

    const filterData = (data, tab) => {
        let newFilteredData = [];
        switch (tab) {
            case "All":
                newFilteredData = data?.[0]?.markets || [];
                break;
            case "Favorites":
                newFilteredData = data?.[0]?.markets?.filter(item =>
                    favoriteMarkets?.includes(item?.id)
                );
                break;
            default:
                break;
        }
        setFilteredMarkets(newFilteredData);
    };

    const handleSort = (columnName, filteredData) => {
        let newSortedData = filteredData
        if (columnName === '') return newSortedData
        if (columnName === "name") {
            const sorted =
                order && order === "ASC"
                    ? filteredData.sort((a, b) =>
                        a["name"].toLowerCase() > b["name"].toLowerCase() ? 1 : -1
                    )
                    : filteredData.sort((a, b) =>
                        a["name"].toLowerCase() < b["name"].toLowerCase() ? 1 : -1
                    );
            newSortedData = sorted;
        }
        else if (columnName === "price") {
            const sorted =
                order && order === "ASC"
                    ? filteredData.sort((a, b) =>
                        a["currentMarketPrice"] > b["currentMarketPrice"] ? 1 : -1
                    )
                    : filteredData.sort((a, b) =>
                        a["currentMarketPrice"] < b["currentMarketPrice"] ? 1 : -1
                    );
            newSortedData = sorted;
        }
        else if (columnName === "dayChange") {
            const sorted =
                order && order === "ASC"
                    ? filteredData.sort((a, b) =>
                        a["dayChange"] > b["dayChange"] ? 1 : -1
                    )
                    : filteredData.sort((a, b) =>
                        a["dayChange"] < b["dayChange"] ? 1 : -1
                    );
            newSortedData = sorted;
        }
        const sortingType = order && order === "ASC" ? "DSC" : "ASC";
        setOrder(sortingType);
        setActiveColumn({ key: columnName, value: true });
        setFilteredMarkets(newSortedData);
    };

    function calculateMaxVolumeOfOrderBook(orderBook, orderBookType) {
        try {
            const { buy, sell } = orderBook;
            if (orderBookType?.toLowerCase() === "buy") {
                const bidVolumes = buy.map(bid => bid.remfiat);
                const maxBidVolume = Math.max(...bidVolumes);
                return maxBidVolume;
            } else {
                const askVolumes = sell.map(ask => ask.remfiat);
                const maxAskVolume = Math.max(...askVolumes);
                return maxAskVolume;
            }
        } catch (error) {
            return 0;
        };
    };

    return (
        <>
            <div className={styles.exchange}>
                {loading && <LoaderScreen />}
                <div className={styles.marketScroll}>
                    {socketMarketData?.length > 0 && (
                        <MarketScroller markets={socketMarketData} />
                    )}
                </div>
                <Main
                    selectedMarket={selectedMarket}
                    currentMarketPrice={currentMarketPrice}
                    priceColor={priceColor}
                    orderData={orderData}
                />
                <div className={styles.nav}>
                    {navigation.map((x, index) => (
                        <button
                            className={cn(styles.link, {
                                [styles.active]: index === activeIndex,
                            })}
                            onClick={() => setActiveIndex(index)}
                            key={index}
                        >
                            {x}
                        </button>
                    ))}
                </div>
                {isTablet ? (
                    <>
                        <Actions
                            OrderTypeNavigation={OrderTypeNavigation}
                            orderData={orderData}
                            slug={slug}
                            newOrderHandler={newOrderHandler}
                            userStatus={userStatus}
                        />
                        {activeIndex === 0 && (
                            <div className={styles.box}>
                                {renderCharts()}
                            </div>
                        )}
                        {activeIndex === 1 && (
                            <div className={styles.box}>
                                <Balance
                                    buy={buyOrdersData}
                                    sell={sellOrdersData}
                                    orderData={orderData}
                                    currentMarketPrice={currentMarketPrice}
                                    priceColor={priceColor}
                                />
                            </div>
                        )}
                        {activeIndex === 2 && (
                            <div className={styles.box}>
                                <Currency
                                    marketNavigation={marketNavigation}
                                    marketSearch={marketSearch}
                                    setMarketSearch={setMarketSearch}
                                    setMarketActiveIndex={setMarketActiveIndex}
                                    marketActiveIndex={marketActiveIndex}
                                    filteredMarkets={filteredMarkets}
                                    handleTabClick={handleTabClick}
                                    toggleFavorite={toggleFavorite}
                                    isFavorite={isFavorite}
                                    handleSort={handleSort}
                                    activeColumn={activeColumn}
                                    setCurrentMarketPrice={setCurrentMarketPrice}
                                />
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className={styles.row}>
                            <div className={styles.col}>
                                <Balance
                                    buy={buyOrdersData}
                                    sell={sellOrdersData}
                                    orderData={orderData}
                                    currentMarketPrice={currentMarketPrice}
                                    priceColor={priceColor}
                                />
                            </div>
                            <div className={styles.col}>
                                {renderCharts()}
                                <Actions
                                    OrderTypeNavigation={OrderTypeNavigation}
                                    orderData={orderData}
                                    slug={slug}
                                    newOrderHandler={newOrderHandler}
                                    userStatus={userStatus}
                                />
                            </div>
                            <div className={styles.col}>
                                <Currency
                                    marketNavigation={marketNavigation}
                                    marketSearch={marketSearch}
                                    setMarketSearch={setMarketSearch}
                                    setMarketActiveIndex={setMarketActiveIndex}
                                    marketActiveIndex={marketActiveIndex}
                                    filteredMarkets={filteredMarkets}
                                    handleTabClick={handleTabClick}
                                    toggleFavorite={toggleFavorite}
                                    isFavorite={isFavorite}
                                    handleSort={handleSort}
                                    activeColumn={activeColumn}
                                    setCurrentMarketPrice={setCurrentMarketPrice}
                                />
                            </div>
                        </div>
                        <Table
                            orderData={orderData}
                            setCancelOrderDetails={setCancelOrderDetails}
                            setCancelModalVisible={setCancelModalVisible}
                        />
                    </>
                )}
            </div>
            <Modal
                onClose={() => { setCancelModalVisible(false); }}
                visible={cancelModalVisible}
            >
                <CancelOrder
                    setCancelModalVisible={setCancelModalVisible}
                    cancelOrderDetails={cancelOrderDetails}
                    cancelOrderHandler={cancelOrderHandler}
                />
            </Modal>
        </>
    );
};

export default Exchange;
