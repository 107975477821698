import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Action.module.sass";
import Icon from "../../../../../components/Icon";
import { formatFeeHandler, getDigitsAfterDecimal } from "../../../../../components/helper";

const takerFee = parseFloat(process.env.REACT_APP_TAKER_FEE);
const makerFee = parseFloat(process.env.REACT_APP_MAKER_FEE);
const spread = parseFloat(process.env.REACT_APP_ORDER_SPREAD);

const Action = ({
  title,
  limit,
  classButton,
  buttonText,
  orderType,
  orderData,
  slug,
  defaultBuyLimitPrice,
  newOrderHandler,
  userStatus
}) => {
  const [buyLimitPrice, setBuyLimitPrice] = useState(defaultBuyLimitPrice);
  const [sellLimitPrice, setSellLimitPrice] = useState(defaultBuyLimitPrice);
  const [buyAmount, setBuyAmount] = useState("");
  const [sellAmount, setSellAmount] = useState("");
  const [buyWarning, setBuyWarning] = useState("");
  const [sellWarning, setSellWarning] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [buyTotal, setBuyTotal] = useState("");
  const [sellTotal, setSellTotal] = useState("");
  const [buyEstFee, setBuyEstFee] = useState("0.00");
  const [sellEstFee, setSellEstFee] = useState("0.00");

  useEffect(() => {
    setBuyAmount("");
    setSellAmount("");
    setBuyWarning("");
    setSellWarning("");
    setBuyTotal("");
    setSellTotal("");
    setSellEstFee("0.00");
    setBuyEstFee("0.00");
  }, [slug, orderData]);

  useEffect(() => {
    setBuyLimitPrice(defaultBuyLimitPrice);
    setSellLimitPrice(defaultBuyLimitPrice);
    setIsButtonDisabled(true);
  }, [orderData]);

  useEffect(() => {
    if (orderType?.toLowerCase() === "buy") {
      setIsButtonDisabled(buyLimitPrice <= 0 || !buyLimitPrice || !buyAmount || parseFloat(buyAmount) <= 0 || parseFloat(buyAmount) > parseFloat(orderData?.currencyBalance) || parseFloat(buyAmount) < parseFloat(process.env.REACT_APP_MINIMUM_ORDER_AMOUNT));
    }
    else {
      setIsButtonDisabled(!sellLimitPrice || !sellAmount || parseFloat(sellAmount) <= 0 || parseFloat(sellAmount) > parseFloat(orderData?.coinBalance) || (parseFloat(sellAmount) * parseFloat(sellLimitPrice)) < parseFloat(process.env.REACT_APP_MINIMUM_ORDER_AMOUNT));
    }
  }, [buyLimitPrice, buyAmount, sellLimitPrice, sellAmount, orderType]);

  const handleBuyAmountChange = (value) => {
    const regex = /^\d*\.?\d*$/;
    if (regex.test(value) || value === "") {
      setBuyAmount(value);

      if (value === "") {
        setBuyWarning("");
        setBuyTotal("");
        setBuyEstFee("0.00");
      }
      else if (parseFloat(value) < parseFloat(process.env.REACT_APP_MINIMUM_ORDER_AMOUNT)) {
        setBuyWarning(`Amount should be greater than or equal to ${process.env.REACT_APP_MINIMUM_ORDER_AMOUNT} ${slug?.split("-")?.[1]?.toUpperCase()}.`)
      }
      else if (parseFloat(value) <= 0) {
        setBuyWarning("Amount must be greater than 0");
        setBuyEstFee("0.00");
      }
      else if (parseFloat(value) > parseFloat(orderData?.currencyBalance)) {
        setBuyWarning("Insufficient balance");
        setBuyEstFee("0.00");
        setBuyTotal("0.00");
      }
      else if (parseFloat(value) < parseFloat(process.env.REACT_APP_MINIMUM_ORDER_AMOUNT)) {
        setBuyWarning(`Minimum amount should be more than or equal to ${parseFloat(process.env.REACT_APP_MINIMUM_ORDER_AMOUNT)} ${slug.split("-")[1]}.`);
        setBuyEstFee("0.00");
        setBuyTotal("");
      }
      else {
        setBuyWarning("");

        let fiatAmount = (1 - (takerFee / 100)) * parseFloat(value);
        let fiatSpreadCalculation = (1 - (spread / 100)) * parseFloat(fiatAmount);
        let fiatSpread = parseFloat(fiatAmount) - parseFloat(fiatSpreadCalculation);

        let cryptoSpread = parseFloat(fiatSpread) / parseFloat(buyLimitPrice);
        let appCryptoTotal = parseFloat(fiatAmount) / parseFloat(buyLimitPrice);
        let customerCryptoTotal = (parseFloat(appCryptoTotal) - parseFloat(cryptoSpread));
        setBuyTotal(customerCryptoTotal ? getDigitsAfterDecimal(customerCryptoTotal, orderData?.current_market_details?.[0]?.amountDecimalPrecision) : "");

        const formattedFee = formatFeeHandler(value, orderData?.current_market_details?.[0]?.priceDecimalPrecision);
        setBuyEstFee(formattedFee);
      }
    }
  };

  const handleSellAmountChange = (value) => {
    const regex = /^\d*\.?\d*$/;
    if (regex.test(value) || value === "") {
      setSellAmount(value);

      if (value === "") {
        setSellWarning("");
        setSellTotal("");
        setSellEstFee("0.00");
      }
      else if (parseFloat(value) <= 0) {
        setSellWarning("Amount must be greater than 0");
        setSellEstFee("0.00");
      }
      else if (parseFloat(value) > parseFloat(orderData?.coinBalance)) {
        setSellWarning("Insufficient balance");
        setSellEstFee("0.00");
        setSellTotal("0.00");
      }
      else if ((parseFloat(value) * parseFloat(sellLimitPrice)) < parseFloat(process.env.REACT_APP_MINIMUM_ORDER_AMOUNT)) {
        setSellWarning(`Minimum amount to sell should be more than or equal to ${parseFloat(process.env.REACT_APP_MINIMUM_ORDER_AMOUNT)} ${slug.split("-")[1]}.`);
        setSellEstFee("0.00");
        setSellTotal("");
      }
      else {
        setSellWarning("");

        let fiatSpreadCalculation = (1 - (spread / 100)) * parseFloat(sellLimitPrice);
        let fiatSpread = parseFloat(sellLimitPrice) - parseFloat(fiatSpreadCalculation);
        let cryptoAmount = (1 - (makerFee / 100)) * parseFloat(value);
        let appFiatTotal = parseFloat(sellLimitPrice) * parseFloat(cryptoAmount);
        let customerFiatTotal = parseFloat(appFiatTotal) - parseFloat(fiatSpread);

        setSellTotal(customerFiatTotal ? getDigitsAfterDecimal(customerFiatTotal, orderData?.current_market_details?.[0]?.priceDecimalPrecision) : "");

        const formattedFee = formatFeeHandler(value, orderData?.current_market_details?.[0]?.amountDecimalPrecision);
        setSellEstFee(formattedFee);
      }
    }
  };

  const currencyHandler = (amount, priceDecimalPrecision, amountDecimalPrecision) => {
    if (orderType?.toLowerCase() === "buy") {
      const value = getDigitsAfterDecimal(amount, priceDecimalPrecision);
      if (value < parseFloat(process.env.REACT_APP_MINIMUM_ORDER_AMOUNT)) {
        setBuyWarning(`Minimum amount should be more than or equal to ${parseFloat(process.env.REACT_APP_MINIMUM_ORDER_AMOUNT)} ${slug.split("-")[1]}.`);
      }
      setBuyAmount(value);
      let fiatAmount = (1 - (takerFee / 100)) * parseFloat(value);
      let fiatSpreadCalculation = (1 - (spread / 100)) * parseFloat(fiatAmount);
      let fiatSpread = parseFloat(fiatAmount) - parseFloat(fiatSpreadCalculation);

      let cryptoSpread = parseFloat(fiatSpread) / parseFloat(buyLimitPrice);
      let appCryptoTotal = parseFloat(fiatAmount) / parseFloat(buyLimitPrice);
      let customerCryptoTotal = (parseFloat(appCryptoTotal) - parseFloat(cryptoSpread));
      setBuyTotal(customerCryptoTotal ? getDigitsAfterDecimal(customerCryptoTotal, amountDecimalPrecision) : "");

      const formattedFee = formatFeeHandler(value, priceDecimalPrecision);
      setBuyEstFee(formattedFee);
    }
    else {
      const value = getDigitsAfterDecimal(amount, amountDecimalPrecision);
      if ((parseFloat(value) * parseFloat(sellLimitPrice)) < parseFloat(process.env.REACT_APP_MINIMUM_ORDER_AMOUNT)) {
        setSellWarning(`Minimum amount should be more than or equal to ${parseFloat(process.env.REACT_APP_MINIMUM_ORDER_AMOUNT)} ${slug.split("-")[1]}.`);
      }
      let fiatSpreadCalculation = (1 - (spread / 100)) * parseFloat(sellLimitPrice);
      let fiatSpread = parseFloat(sellLimitPrice) - parseFloat(fiatSpreadCalculation);
      let cryptoAmount = (1 - (makerFee / 100)) * parseFloat(value);
      let appFiatTotal = parseFloat(sellLimitPrice) * parseFloat(cryptoAmount);
      let customerFiatTotal = parseFloat(appFiatTotal) - parseFloat(fiatSpread);
      setSellAmount(value);
      setSellTotal(customerFiatTotal ? getDigitsAfterDecimal(customerFiatTotal, priceDecimalPrecision) : "");

      const formattedFee = formatFeeHandler(value, amountDecimalPrecision);
      setSellEstFee(formattedFee);
    }
  };

  const submitHandler = () => {
    if (userStatus?.kyc_verification !== parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE)) {
      setIsButtonDisabled(true);
      setBuyWarning("Please complete your kyc verification.");
    }
    else {
      if (limit) {
        if (orderType?.toLowerCase() === "buy") {
          newOrderHandler(orderData?.current_market_details?.[0]?.market_id, parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER), buyLimitPrice, buyAmount, null);
        }
        else {
          newOrderHandler(orderData?.current_market_details?.[0]?.market_id, parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER), sellLimitPrice, null, sellAmount);
        }
      }
      else {
        if (orderType?.toLowerCase() === "buy") {
          newOrderHandler(orderData?.current_market_details?.[0]?.market_id, parseInt(process.env.REACT_APP_BUY_MARKET_ORDER), null, buyAmount, null);
        }
        else {
          newOrderHandler(orderData?.current_market_details?.[0]?.market_id, parseInt(process.env.REACT_APP_SELL_MARKET_ORDER), null, null, sellAmount);
        }
      }
    }
  };

  const handleBuyLimitPriceChange = (value) => {
    const regex = /^\d*\.?\d*$/;
    if (regex.test(value) || value === "") {
      setBuyLimitPrice(value);

      if (value === "") {
        setBuyWarning("");
        setBuyTotal("");
        setBuyEstFee("0.00");
      }
      else if (parseFloat(value) <= 0) {
        setBuyWarning("Limit Price must be greater than 0");
        setBuyEstFee("0.00");
      }
      else {
        setBuyWarning("");

        let fiatAmount = (1 - (takerFee / 100)) * parseFloat(buyAmount);
        let fiatSpreadCalculation = (1 - (spread / 100)) * parseFloat(fiatAmount);
        let fiatSpread = parseFloat(fiatAmount) - parseFloat(fiatSpreadCalculation);

        let cryptoSpread = parseFloat(fiatSpread) / parseFloat(value);
        let appCryptoTotal = parseFloat(fiatAmount) / parseFloat(value);
        let customerCryptoTotal = (parseFloat(appCryptoTotal) - parseFloat(cryptoSpread));
        setBuyTotal(customerCryptoTotal ? getDigitsAfterDecimal(customerCryptoTotal, orderData?.current_market_details?.[0]?.amountDecimalPrecision) : "");

        const formattedFee = formatFeeHandler(buyAmount, orderData?.current_market_details?.[0]?.priceDecimalPrecision);
        setBuyEstFee(formattedFee);
      }
    }
  };

  const handleSellLimitPriceChange = (value) => {
    const regex = /^\d*\.?\d*$/;
    if (regex.test(value) || value === "") {
      setSellLimitPrice(value);

      if (value === "") {
        setSellWarning("");
        setSellTotal("");
        setSellEstFee("0.00");
      }
      else if (parseFloat(value) <= 0) {
        setSellWarning("Limit Price must be greater than 0");
        setSellEstFee("0.00");
      }
      else {
        setSellWarning("");

        let fiatSpreadCalculation = (1 - (spread / 100)) * parseFloat(value);
        let fiatSpread = parseFloat(value) - parseFloat(fiatSpreadCalculation);
        let cryptoAmount = (1 - (makerFee / 100)) * parseFloat(sellAmount);
        let appFiatTotal = parseFloat(value) * parseFloat(cryptoAmount);
        let customerFiatTotal = parseFloat(appFiatTotal) - parseFloat(fiatSpread);

        setSellTotal(customerFiatTotal ? getDigitsAfterDecimal(customerFiatTotal, orderData?.current_market_details?.[0]?.priceDecimalPrecision) : "");

        const formattedFee = formatFeeHandler(sellAmount, orderData?.current_market_details?.[0]?.amountDecimalPrecision);
        setSellEstFee(formattedFee);
      }
    }
  };

  return (
    <>
      <div className={styles.head}>
        <div className={styles.title}>{title}</div>
        <div className={styles.counter} onClick={() => {
          orderType?.toLowerCase() === "buy" ?
            currencyHandler(orderData?.currencyBalance, orderData?.current_market_details?.[0]?.priceDecimalPrecision, orderData?.current_market_details?.[0]?.amountDecimalPrecision)
            :
            currencyHandler(orderData?.coinBalance, orderData?.current_market_details?.[0]?.priceDecimalPrecision, orderData?.current_market_details?.[0]?.amountDecimalPrecision)
        }}>
          <Icon name="wallet" size="16" />
          {orderType?.toLowerCase() === "buy"
            ? `${getDigitsAfterDecimal(orderData?.currencyBalance, orderData?.current_market_details?.[0]?.priceDecimalPrecision)} ${slug?.split("-")?.[1]?.toUpperCase()}`
            : `${getDigitsAfterDecimal(orderData?.coinBalance, orderData?.current_market_details?.[0]?.amountDecimalPrecision)} ${slug?.split("-")?.[0]?.toUpperCase()}`}
        </div>
      </div>
      {limit ? (
        orderType?.toLowerCase() === "buy" ? (
          <>
            <label className={styles.field}>
              <div className={styles.label}>Limit Price</div>
              <input
                className={styles.input}
                type="text"
                name="price"
                required
                value={buyLimitPrice}
                placeholder="0.00"
                onChange={(e) => handleBuyLimitPriceChange(e.target.value)}
              />
              <div className={styles.currency}>{slug?.split("-")?.[1]?.toUpperCase()}</div>
            </label>
            <label className={styles.field}>
              <div className={styles.label}>Amount</div>
              <input
                className={styles.input}
                type="text"
                name="amount"
                required
                value={buyAmount}
                placeholder="0.00"
                onChange={(e) => handleBuyAmountChange(e.target.value)}
              />
              <div className={styles.currency}>{slug?.split("-")?.[1]?.toUpperCase()}</div>
            </label>
            <div className={styles.errorContainer}>
              <span className={styles.errorMessage}>{buyWarning}</span>
            </div>
            <label className={styles.field}>
              <div className={styles.label}>Total</div>
              <input
                className={styles.input}
                type="text"
                name="total"
                required
                disabled={true}
                defaultValue={buyTotal}
                placeholder="0.00"
              />
              <div className={styles.currency}>{slug?.split("-")?.[0]?.toUpperCase()}</div>
            </label>
            <div className={styles.estFee}>
              <div className={styles.titleFeeText}>Est. Fee(≈)</div>
              <div className={styles.titleFee}>{buyEstFee} <small>{orderData?.current_market_details?.[0]?.currency?.toUpperCase()}</small></div>
            </div>
          </>
        ) : (
          <>
            <label className={styles.field}>
              <div className={styles.label}>Limit Price</div>
              <input
                className={styles.input}
                type="text"
                name="price"
                required
                value={sellLimitPrice}
                placeholder="0.00"
                onChange={(e) => handleSellLimitPriceChange(e.target.value)}
              />
              <div className={styles.currency}>{slug?.split("-")?.[1]?.toUpperCase()}</div>
            </label>
            <label className={styles.field}>
              <div className={styles.label}>Amount</div>
              <input
                className={styles.input}
                type="text"
                name="amount"
                required
                value={sellAmount}
                placeholder="0.00"
                onChange={(e) => handleSellAmountChange(e.target.value)}
              />
              <div className={styles.currency}>{slug?.split("-")?.[0]?.toUpperCase()}</div>
            </label>
            <div className={styles.errorContainer}>
              <span className={styles.errorMessage}>{sellWarning}</span>
            </div>
            <label className={styles.field}>
              <div className={styles.label}>Total</div>
              <input
                className={styles.input}
                type="text"
                name="total"
                required
                disabled={true}
                defaultValue={sellTotal}
                placeholder="0.00"
              />
              <div className={styles.currency}>{slug?.split("-")?.[1]?.toUpperCase()}</div>
            </label>
            <div className={styles.estFee}>
              <div className={styles.titleFeeText}>Est. Fee(≈)</div>
              <div className={styles.titleFee}>{sellEstFee} <small>{orderData?.current_market_details?.[0]?.coin?.toUpperCase()}</small></div>
            </div>
          </>
        )
      ) : (
        <>
          {orderType?.toLowerCase() === "buy" ? (
            <>
              <label className={styles.field}>
                <div className={styles.label}>Price</div>
                <input
                  className={styles.input}
                  type="text"
                  name="amount"
                  defaultValue={"MarketPrice"}
                  disabled={true}
                />
                <div className={styles.currency}>{orderData?.current_market_details?.[0]?.currency?.toUpperCase()}</div>
              </label>
              <label className={styles.field}>
                <div className={styles.label}>Amount</div>
                <input
                  className={styles.input}
                  type="text"
                  name="total"
                  required
                  value={buyAmount}
                  onChange={(e) => handleBuyAmountChange(e.target.value)}
                  placeholder="0.00"
                />
                <div className={styles.currency}>{orderData?.current_market_details?.[0]?.currency?.toUpperCase()}</div>
              </label>
              <div className={styles.errorContainer}>
                <span className={styles.errorMessage}>{buyWarning}</span>
              </div>
              <label className={styles.hideClass}>
                <div className={styles.label}>Total</div>
                <input
                  className={styles.input}
                  type="text"
                  name="total"
                  required
                  disabled={true}
                  readOnly
                  placeholder="0.00"
                />
              </label>
              <div className={styles.estFee}>
                <div className={styles.titleFeeText}>Est. Fee(≈)</div>
                <div className={styles.titleFee}>{buyEstFee} <small>{orderData?.current_market_details?.[0]?.currency?.toUpperCase()}</small></div>
              </div>
            </>
          ) : (
            <>
              <label className={styles.field}>
                <div className={styles.label}>Price</div>
                <input
                  className={styles.input}
                  type="text"
                  name="amount"
                  defaultValue={"MarketPrice"}
                  disabled={true}
                />
                <div className={styles.currency}>{orderData?.current_market_details?.[0]?.currency?.toUpperCase()}</div>
              </label>
              <label className={styles.field}>
                <div className={styles.label}>Amount</div>
                <input
                  className={styles.input}
                  type="text"
                  name="total"
                  required
                  value={sellAmount}
                  onChange={(e) => handleSellAmountChange(e.target.value)}
                  placeholder="0.00"
                />
                <div className={styles.currency}>{orderData?.current_market_details?.[0]?.coin?.toUpperCase()}</div>
              </label>
              <div className={styles.errorContainer}>
                <span className={styles.errorMessage}>{sellWarning}</span>
              </div>
              <label className={styles.hideClass}>
                <div className={styles.label}>Total</div>
                <input
                  className={styles.input}
                  type="text"
                  name="total"
                  required
                  disabled={true}
                  readOnly
                />
              </label>
              <div className={styles.estFee}>
                <div className={styles.titleFeeText}>Est. Fee(≈)</div>
                <div className={styles.titleFee}>{sellEstFee} <small>{orderData?.current_market_details?.[0]?.coin?.toUpperCase()}</small></div>
              </div>
            </>
          )}
        </>
      )}
      <button
        disabled={isButtonDisabled}
        className={cn(classButton, styles.button)}
        onClick={() => submitHandler()}
      >
        {buttonText}
      </button>
    </>
  );
};

export default Action;
