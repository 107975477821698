import React from "react";
import styles from "./Form.module.sass";
import Action from "./Action";
import { useMediaQuery } from "react-responsive";
import Icon from "../../../../components/Icon";
import { getDigitsAfterDecimal } from "../../../../components/helper";

const Form = ({
    limit,
    visible,
    setValue,
    orderData,
    slug,
    newOrderHandler,
    userStatus,
}) => {
    const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });

    return (
        <div className={styles.form}>
            {isTablet ? (
                <>
                    {visible ? (
                        <>
                            <div className={styles.head}>
                                <div className={styles.title}>Place order</div>
                                <button
                                    className={styles.close}
                                    onClick={() => setValue(false)}
                                >
                                    <Icon name="close-circle" size="24" />
                                </button>
                            </div>
                            <Action
                                title={`Buy ${slug?.split("-")?.[0]?.toUpperCase()}`}
                                limit={limit}
                                classButton="button-green"
                                buttonText={`Buy ${slug?.split("-")?.[0]?.toUpperCase()}`}
                                defaultBuyLimitPrice={getDigitsAfterDecimal(orderData?.current_market_details?.[0]?.currentMarketPrice, orderData?.current_market_details?.[0]?.priceDecimalPrecision) || ""}
                                orderType={"buy"}
                                orderData={orderData}
                                slug={slug}
                                newOrderHandler={newOrderHandler}
                                userStatus={userStatus}
                            />
                        </>
                    ) : (
                        <>
                            <div className={styles.head}>
                                <div className={styles.title}>Place order</div>
                                <button
                                    className={styles.close}
                                    onClick={() => setValue(false)}
                                >
                                    <Icon name="close-circle" size="24" />
                                </button>
                            </div>
                            <Action
                                title={`Sell ${slug?.split("-")?.[0]?.toUpperCase()}`}
                                limit={limit}
                                classButton="button-red"
                                buttonText={`Sell ${slug?.split("-")?.[0]?.toUpperCase()}`}
                                defaultBuyLimitPrice={getDigitsAfterDecimal(orderData?.current_market_details?.[0]?.currentMarketPrice, orderData?.current_market_details?.[0]?.priceDecimalPrecision) || ""}
                                orderType={"sell"}
                                orderData={orderData}
                                slug={slug}
                                newOrderHandler={newOrderHandler}
                                userStatus={userStatus}
                            />
                        </>
                    )}
                </>
            ) : (
                <div className={styles.row}>
                    <div className={styles.col}>
                        <Action
                            title={`Buy ${slug?.split("-")?.[0]?.toUpperCase()}`}
                            limit={limit}
                            classButton="button-green"
                            buttonText={`Buy ${slug?.split("-")?.[0]?.toUpperCase()}`}
                            defaultBuyLimitPrice={getDigitsAfterDecimal(orderData?.current_market_details?.[0]?.currentMarketPrice, orderData?.current_market_details?.[0]?.priceDecimalPrecision) || ""}
                            orderType={"buy"}
                            orderData={orderData}
                            slug={slug}
                            newOrderHandler={newOrderHandler}
                            userStatus={userStatus}
                        />
                    </div>
                    <div className={styles.col}>
                        <Action
                            title={`Sell ${slug?.split("-")?.[0]?.toUpperCase()}`}
                            limit={limit}
                            classButton="button-red"
                            buttonText={`Sell ${slug?.split("-")?.[0]?.toUpperCase()}`}
                            defaultBuyLimitPrice={getDigitsAfterDecimal(orderData?.current_market_details?.[0]?.currentMarketPrice, orderData?.current_market_details?.[0]?.priceDecimalPrecision) || ""}
                            orderType={"sell"}
                            orderData={orderData}
                            slug={slug}
                            newOrderHandler={newOrderHandler}
                            userStatus={userStatus}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Form;
