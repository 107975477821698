const ProtectedRoutes = ({ children }) => {
    const isAuthenticated = !!localStorage.getItem('signature');

    if (!isAuthenticated) {
        window.location.replace(process.env.REACT_APP_GLOBAL_URL);
        return null;
    }

    return children;
};

export default ProtectedRoutes;
