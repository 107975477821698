import React, { useEffect, useState } from "react";
import styles from "./OrdersHistory.module.sass";
import Modal from "../../components/Modal";
import Withdraw from "../../components/Withdraw";
import Funds from "./Funds";
import Main from "./Main";
import Order from "../../components/Order";
import requestHandler from "../../actions/httpClient";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { socketDataReceived } from "../../actions/markets";

const OrdersHistory = () => {
  const [pageno, setPageno] = useState(1);
  const [completeOrders, setCompleteOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(true);
  const dispatch = useDispatch();
  const currencyOptions = [
    { cId: "all", cValue: "All" },
    { cId: parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER), cValue: "Buy" },
    { cId: parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER), cValue: "Sell" }
  ];
  const [currencyText, setCurrencyText] = useState(currencyOptions[0]?.cValue);
  const [currencyValue, setCurrencyValue] = useState(currencyOptions[0]?.cId);
  const [markets, setMarkets] = useState([]);
  const [marketValue, setMarketValue] = useState("All");
  const { socketMarketData } = useSelector(state => state.markets);
  const orderTypesValues = ["All", "Limit", "Market"];
  const [orderTypeValue, setOrderTypeValue] = useState(orderTypesValues[0]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      autoConnect: false,
      transports: ['websocket']
    });
    let marketSocket = "new_europe_markets_web";
    socket.connect();
    const marketData = "europe_markets";

    socket.emit("marketData", marketData);

    socket.on(marketSocket, (markets) => {
      dispatch(socketDataReceived(markets));
      setLoading(false);
      socket.disconnect();
    });

    const interval = setInterval(() => {
      socket.connect()
      socket.emit("marketData", marketData);
    }, parseInt(process.env.REACT_APP_MARKET_SOCKET_TIME_INTERVAL));

    return () => {
      clearInterval(interval);
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socketMarketData?.length > 0) {
      const markets = socketMarketData?.[0]?.markets?.map(x => x.slug);
      setMarkets(["All", ...markets]);
    }
  }, [socketMarketData]);

  useEffect(() => {
    getCompleteOrderData();
  }, []);

  const getCompleteOrderData = async () => {
    setLoading(true);
    const data = {
      signature: localStorage.getItem("signature"),
    };
    try {
      const completeOrdersPayload = await requestHandler('getAllCompletedOrdersAndMarketOrders', 'post', data, 'jwt_token');
      setCompleteOrder(completeOrdersPayload?.data?.data);
      setLoading(false);
    }
    catch (error) {
      setLoading(false);
    };
  };

  const filteredOrders = completeOrders?.filter(x => {
    const isCurrencyMatch = currencyText === "All" || (
      (currencyText === "Buy" && (
        x?.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) ||
        x?.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER)
      )) ||
      (currencyText === "Sell" && (
        x?.orderType === parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER) ||
        x?.orderType === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER)
      ))
    );

    const isMarketMatch = marketValue === "All" || (x.slug && x.slug.toLowerCase() === marketValue.toLowerCase());
    const orderTypeMap = {
      "All": [
        parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER),
        parseInt(process.env.REACT_APP_BUY_MARKET_ORDER),
        parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER),
        parseInt(process.env.REACT_APP_SELL_MARKET_ORDER)
      ],
      "Limit": [
        parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER),
        parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER),
      ],
      "Market": [
        parseInt(process.env.REACT_APP_BUY_MARKET_ORDER),
        parseInt(process.env.REACT_APP_SELL_MARKET_ORDER)
      ]
    };
    const validOrderTypes = orderTypeMap[orderTypeValue];
    const isTypeMatch = validOrderTypes.includes(x?.orderType);
    return isCurrencyMatch && isMarketMatch && isTypeMatch;
  });

  return (
    <>
      <Order>
        <Main />
        <div className={styles.list}>
          <div className={styles.item}>
            <div className={styles.head}>Orders History</div>
            <div className={styles.body}>
              <Funds
                completeOrders={completeOrders}
                pageno={pageno}
                loading={loading}
                active={active}
                currencyOptions={currencyOptions}
                currencyValue={currencyValue}
                setCurrencyValue={setCurrencyValue}
                marketValue={marketValue}
                setMarketValue={setMarketValue}
                markets={markets}
                currencyText={currencyText}
                setCurrencyText={setCurrencyText}
                orderTypeValue={orderTypeValue}
                orderTypeValues={orderTypesValues}
                setOrderTypeValue={setOrderTypeValue}
                filteredOrders={filteredOrders}
              />
            </div>
          </div>
        </div>
      </Order>
    </>
  );
};

export default OrdersHistory;
