import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import { getDigitsAfterDecimal, toLocaleStringDateFormat } from "../../../../components/helper";
import Icon from "../../../../components/Icon";
import { addNotification } from "../../../../components/Notification";

const Item = ({ item, children }) => {


  return (
    <>
      <div className={cn(styles.item)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.currency}>
              <div className={styles.icon}>
                <img src={item.icon} alt="Coin" />
              </div>
              <div className={styles.details}>
                <div className={styles.info}>{item.symbol.toUpperCase()}</div>
                <div className={styles.text}>{item.name.toUpperCase()}</div>
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.info} title={item.txid}>{item.txid === null || item.txid === undefined ? "---" : item.txid?.length > 20 ? item.txid.substring(0, 19) + '...' : item.txid + " "}
              {item?.txid?.length > 0 && (
                <button className={styles.copy} onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(item.txid).then(() => {
                    addNotification({ title: 'Success', message: 'Text copied successfully', type: 'success' })
                  })
                }}>
                  <div className={styles.btnClass}>
                    <Icon name="copy" size="16" />
                  </div>
                </button>
              )}
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.info} title={item.address}>{item.address && item.address?.length > 20 ? item.address.substring(0, 19) + '...' : item.address + " "}
              <button className={styles.copy} onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(item.address).then(() => {
                  addNotification({ title: 'Success', message: 'Text copied successfully', type: 'success' })
                })
              }}>
                <div className={styles.btnClass}>
                  <Icon name="copy" size="16" />
                </div>
              </button>
            </div>
          </div>
          {/* <div className={styles.col}>
          <div className={styles.info}>
            {item.fname === "" || item.fname === undefined || item.fname === null ? "---" : `${item.fname} ${item.lname}`}
          </div>
        </div> */}
          <div className={styles.col}>
            <div className={styles.info}>{getDigitsAfterDecimal(item?.amount, item?.decimalPrecision)}</div>
          </div>
          <div className={styles.col}>
            {
              parseInt(item.status) === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_UNVERIFIED) &&
              (<div className={cn("category-blue")}>Unverified</div>)
            }
            {parseInt(item.status) === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_PENDING) &&
              (<div className={cn("category-blue")}>Pending</div>)
            }
            {parseInt(item.status) === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO__TRANSACTION_STATUS_PROCESS) &&
              (<div className={cn("category-blue")}>Process</div>)
            }
            {parseInt(item.status) === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_SUCCESS) &&
              (<div className={cn("category-green")}>Success</div>)
            }
            {parseInt(item.status) === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_CANCEL) &&
              (<div className={cn("category-red")}>Cancel</div>)
            }
            {parseInt(item.status) === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_DECLINE) &&
              (<div className={cn("category-red")}>Decline</div>)
            }
          </div>
          <div className={styles.col}>
            <div className={styles.info}>{toLocaleStringDateFormat(item.updatedAt)}</div>
          </div>
        </div>
        <div className={styles.btns}>{children}</div>
      </div>
    </>
  );
};

export default Item;
