import React, { useState, useEffect } from 'react';
import styles from "./Table.module.sass";
import BankSkeleton from '../../../components/Skeleton/BankSkeleton';
import Modal from '../../../components/Modal';
import ConfirmationModal from './ConfirmationModal';
import cn from 'classnames';

const Table = ({
  bankList,
  loading,
  primaryBankHandler,
}) => {
  const [selectedBank, setSelectedBank] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [accountNumber, setAccountNumber] = useState();

  useEffect(() => {
    const primaryBank = bankList.find(bank => bank.is_Primary === true);

    if (primaryBank) {
      setSelectedBank(primaryBank.account_number);
    }
    else {
      const completedBank = bankList.find(bank => bank.status === parseInt(process.env.REACT_APP_BANK_VARIFICATION_COMPLETE));

      if (completedBank) {
        setSelectedBank(completedBank.account_number);

        primaryBankHandler(completedBank.account_number);
      }
    }
  }, [bankList]);

  const handleBankSelect = (accountNumber) => {
    setShowPopup(true);
    setAccountNumber(accountNumber);
  };

  return (
    <div className={styles.container}>
      <div className={cn("h2", styles.titleText)}>Added Bank</div>
      <ul className={styles.list}>
        {loading ? (
          <BankSkeleton count={2} height={10} />
        ) : (
          bankList.map((bank) => (
            <li
              key={bank.account_number}
              className={`${styles.listitem} ${selectedBank === bank.account_number ? styles.selected : ''
                }`}
            >
              <input
                type="radio"
                name="bank"
                id={bank.account_number}
                value={bank.name}
                onChange={() => handleBankSelect(bank.account_number)}
                checked={selectedBank === bank.account_number}
                disabled={
                  bank.status !== parseInt(process.env.REACT_APP_BANK_VARIFICATION_COMPLETE, 10)
                }
              />
              <label className={styles.label} htmlFor={bank.account_number}>
                <div className={styles.bankDetails}>
                  <div className={styles.bankName}>{bank.paymnet_mode_name}</div>
                  <div className={styles.bankAccountNumber}>{bank.account_number}</div>
                </div>
                {selectedBank === bank.account_number && (
                  <span className={styles.primaryBadge}>Primary</span>
                )}
                <div className={styles.badges}>
                  {bank.status === parseInt(process.env.REACT_APP_BANK_VARIFICATION_PENDING, 10) && (
                    <span className={styles.statusBadge}>Pending</span>
                  )}
                  {bank.status === parseInt(process.env.REACT_APP_BANK_VARIFICATION_PROCESS, 10) && (
                    <span className={styles.statusBadge}>In Process</span>
                  )}
                  {bank.status === parseInt(process.env.REACT_APP_BANK_VARIFICATION_COMPLETE, 10) && (
                    <span className={styles.statusBadge}>Completed</span>
                  )}
                  {bank.status === parseInt(process.env.REACT_APP_BANK_VARIFICATION_FAILURE, 10) && (
                    <span className={styles.statusBadge}>Failure</span>
                  )}
                  {bank.status === parseInt(process.env.REACT_APP_BANK_VARIFICATION_REJECT, 10) && (
                    <span className={styles.statusBadge}>Rejected</span>
                  )}
                </div>
              </label>
            </li>
          ))
        )}
      </ul>

      <Modal onClose={() => setShowPopup(false)} visible={showPopup}>
        <ConfirmationModal
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          primaryBankHandler={primaryBankHandler}
          accountNumber={accountNumber}
        />
      </Modal>
    </div>

  );
};

export default Table;
