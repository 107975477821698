import React, { useEffect, useState } from "react";
import Main from "./Main";
import Details from "./Details";
import requestHandler from "../../actions/httpClient";
// import { socket } from "../../socket";
import { useDispatch, useSelector } from "react-redux";
import { userStatusCreator } from "../../actions/getUserStatus";
import { socketDataReceived } from "../../actions/markets/index";
import { io } from "socket.io-client";

const sortMarketNav = ["All", "Favorites", "Gainers", "Losers", "Volume"];

const Market = () => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch();
  const { userStatus } = useSelector(state => (state.getUserStatus));
  const [favoriteMarkets, setFavoriteMarkets] = useState(userStatus?.userFavMarkets?.length > 0 ? userStatus?.userFavMarkets : []);
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState({ column: null, order: 'ASC' });
  const [activeColumn, setActiveColumn] = useState({ key: "", value: false });
  const [filteredData, setFilteredData] = useState([]);
  const [activeTab, setActiveTab] = useState("All");
  const { socketMarketData } = useSelector((state) => state.markets);

  useEffect(() => {
    if (socketMarketData?.length && activeTab === "All" && activeColumn.key === "") {
      setFilteredData(socketMarketData?.[0]?.markets);
    }
  }, [socketMarketData]);

  useEffect(() => {
    if (userStatus?.length === 0) {
      dispatch(userStatusCreator());
    }
    if (userStatus?.userFavMarkets > 0) {
      setFavoriteMarkets(userStatus?.userFavMarkets);
    }
  }, [userStatus]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      autoConnect: false,
      transports: ['websocket']
    });
    let marketSocket = "new_europe_markets_web";
    socket.connect();
    const marketData = "europe_markets";

    socket.emit("marketData", marketData);

    socket.on(marketSocket, (markets) => {
      dispatch(socketDataReceived(markets));
      setLoading(false);
      socket.disconnect();
    });

    const interval = setInterval(() => {
      socket.connect()
      socket.emit("marketData", marketData);
    }, parseInt(process.env.REACT_APP_MARKET_SOCKET_TIME_INTERVAL));

    return () => {
      clearInterval(interval);
      socket.disconnect();
    };
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    filterData(socketMarketData, tab);
  };

  const toggleFavorite = async (item) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      marketId: item?.id
    };
    try {
      const favoritePayload = await requestHandler("addRemoveFavMarkets", "post", data, "jwt_token");
      setLoading(false);
      if (favoritePayload?.status === 200) {
        dispatch(userStatusCreator());
        setFavoriteMarkets(prevFavorites => {
          const isFavorite = prevFavorites?.includes(item.id);
          if (isFavorite) {
            const updatedFavorites = prevFavorites?.filter(id => id !== item.id);
            if (activeTab === "Favorites") {
              const updatedFilteredData = socketMarketData?.[0]?.markets?.filter(dataItem => updatedFavorites?.includes(dataItem.id));
              setFilteredData(updatedFilteredData);
            }
            return updatedFavorites;
          }
          else {
            return [...prevFavorites, item?.id];
          }
        });
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const handleSort = (columnName, filteredData) => {
    let newSortedData = filteredData
    if (columnName === '') return newSortedData
    if (columnName === "name") {
      const sorted =
        order && order === "ASC"
          ? filteredData.sort((a, b) =>
            a["name"].toLowerCase() > b["name"].toLowerCase() ? 1 : -1
          )
          : filteredData.sort((a, b) =>
            a["name"].toLowerCase() < b["name"].toLowerCase() ? 1 : -1
          );
      newSortedData = sorted;
    }
    else if (columnName === "price") {
      const sorted =
        order && order === "ASC"
          ? filteredData.sort((a, b) =>
            a["currentMarketPrice"] > b["currentMarketPrice"] ? 1 : -1
          )
          : filteredData.sort((a, b) =>
            a["currentMarketPrice"] < b["currentMarketPrice"] ? 1 : -1
          );
      newSortedData = sorted;
    }
    else if (columnName === "dayChange") {
      const sorted =
        order && order === "ASC"
          ? filteredData.sort((a, b) =>
            a["dayChange"] > b["dayChange"] ? 1 : -1
          )
          : filteredData.sort((a, b) =>
            a["dayChange"] < b["dayChange"] ? 1 : -1
          );
      newSortedData = sorted;
    }
    else if (columnName === "weekChange") {
      const sorted =
        order && order === "ASC"
          ? filteredData.sort((a, b) =>
            a["weekChange"] > b["weekChange"] ? 1 : -1
          )
          : filteredData.sort((a, b) =>
            a["weekChange"] < b["weekChange"] ? 1 : -1
          );
      newSortedData = sorted;
    }
    else if (columnName === "marketCap") {
      const sortedData = [...filteredData].sort((a, b) => {
        const capA = parseInt(a?.cap?.replace(/,/g, ''), 10);
        const capB = parseInt(b?.cap?.replace(/,/g, ''), 10);

        return order === 'ASC' ? capA - capB : capB - capA;
      });
      newSortedData = sortedData;
    }
    else if (columnName === "volume") {
      const sortedData = [...filteredData].sort((a, b) => {
        const volumeA = parseInt(a?.volume?.replace(/,/g, ''), 10);
        const volumeB = parseInt(b?.volume?.replace(/,/g, ''), 10);

        return order === 'ASC' ? volumeA - volumeB : volumeB - volumeA;
      });
      newSortedData = sortedData;
    }
    const sortingType = order && order === "ASC" ? "DSC" : "ASC";
    setOrder(sortingType);
    setActiveColumn({ key: columnName, value: true });
    setFilteredData(newSortedData);
  };

  const isFavorite = (marketId) => {
    return favoriteMarkets?.includes(marketId);
  };

  const filterData = (data, tab) => {
    let newFilteredData = [];
    switch (tab) {
      case "All":
        newFilteredData = data?.[0]?.markets || [];
        break;
      case "Favorites":
        newFilteredData = data?.[0]?.markets?.filter(item =>
          favoriteMarkets?.includes(item?.id)
        );
        break;
      case "Gainers":
        newFilteredData = data?.[0]?.markets
          ?.filter(item => item?.dayChange > 0)
          ?.sort((a, b) => b?.dayChange - a?.dayChange);
        break;
      case "Losers":
        newFilteredData = data?.[0]?.markets
          ?.filter(item => item?.dayChange < 0)
          ?.sort((a, b) => a?.dayChange - b?.dayChange);
        break;
      case "Volume":
        newFilteredData = data?.[0]?.markets?.sort(
          (a, b) =>
            parseFloat(b?.volume?.replace(/,/g, "")) -
            parseFloat(a?.volume?.replace(/,/g, ""))
        );
        break;
      default:
        break;
    }
    setFilteredData(newFilteredData);
  };

  return (
    <>
      <Main />
      <Details
        loading={socketMarketData <= 0 && loading}
        isFavorite={isFavorite}
        search={search}
        setSearch={setSearch}
        activeColumn={activeColumn}
        toggleFavorite={toggleFavorite}
        activeTab={activeTab}
        handleSort={handleSort}
        filteredData={filteredData}
        sortMarketNav={sortMarketNav}
        socketMarketData={socketMarketData}
        handleTabClick={handleTabClick}
      />
    </>
  );
};

export default Market;
