import React, { useState } from "react";
import cn from "classnames";
import styles from "./Funds.module.sass";
import Icon from "../../../components/Icon";
import NoDataFound from "../../../components/NoDataFound";
import Item from "./Item";
import Modal from "../../../components/Modal";
import Address from "../../../components/Address";
import { addNotification } from "../../../components/Notification";
import Successfully from "../Successfully";
import requestHandler from "../../../actions/httpClient";
import WalletSkeleton from "../../../components/Skeleton/WalletSkeleton";
import Code from "../Code";
import { useSelector } from "react-redux";

const Funds = ({
  refreshFlag,
  setRefreshFlag,
  children,
  coinsData,
  allAddress,
  handleAddress,
  handleLoadMore,
  pageNo,
  loading,
  setLoading,
}) => {
  const [visibleTransfer, setVisibleTransfer] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [btnProperty, setBtnProperty] = useState(true)
  const [verificationModal, setVerificationModal] = useState(false);
  const [verificationId, setVerificationId] = useState();
  const [withdrawStatus, setWithdrawStatus] = useState("");
  const { userStatus } = useSelector((state) => { return state.getUserStatus });

  //callback props function passed in addres componenet
  // const handleSubmit = async (coin, token, address, ownership, addressProof) => {
  //   setLoading(true);
  //   if (coin === "" || token === "" || address === "" || ownership === "" || addressProof === "") {
  //     addNotification({
  //       title: 'Error',
  //       message: 'Please enter fields',
  //       type: 'danger'
  //     })
  //   }
  //   else {
  //     const signature = localStorage.getItem("signature")
  //     const data = {
  //       'coinId': parseInt(coin),
  //       'network': token,
  //       'address': address,
  //       'ownership': ownership,
  //       'scanOwnership': addressProof,
  //       'signature': signature
  //     }
  //     console.log("data", data);
  //     try {
  //       const response = await requestHandler('newAddress', 'post', data, 'jwt_token', "file")
  //       setLoading(false);
  //       if (response?.status === 200) {
  //         setVisibleTransfer(false)
  //         setIsValid(true)
  //         setVisible(true)
  //         handleAddress(response)
  //         // dispatch(newAddressEmpty())
  //       }
  //     } catch (error) {
  //       setLoading(false);
  //       if (error) {
  //         addNotification({
  //           title: 'Error', message: error?.data?.message[0]?.msg
  //           , type: 'danger'
  //         })
  //         setVisibleTransfer(false)
  //         setIsValid(true)
  //       }
  //     }
  //   }
  // }

  const handleSubmit = async (coin, token, address, ownership, addressProof, tag, firstName, lastName) => {
    setLoading(true);
    const data = {
      'coinId': parseInt(coin),
      'network': token,
      'address': address?.trim(),
      'ownership': ownership,
      'scanOwnership': addressProof,
      'signature': localStorage.getItem("signature"),
      tag: tag,
      fname: firstName,
      lname: lastName
    }
    try {
      const response = await requestHandler('newAddress', 'post', data, 'jwt_token', "file")
      setLoading(false);
      if (response?.status === 201) {
        setVisibleTransfer(false)
        setVerificationModal(true);
        setWithdrawStatus(response?.data?.message?.type);
        setVerificationId(response?.data?.message?.data?.id);
        // dispatch(newAddressEmpty())
      }
    }
    catch (error) {
      setLoading(false);
      if (error) {
        addNotification({
          title: 'Error',
          message: error?.data?.message[0]?.msg,
          type: 'danger'
        })
        setVisibleTransfer(false)
        setIsValid(true)
      };
    };
  };

  const codeScreenHandler = async (firstField, secondField, thirdField, fourthField, fifthField, sixthField, verificationType) => {
    setLoading(true);
    const code = firstField + secondField + thirdField + fourthField + fifthField + sixthField;
    let data = {
      code: code,
      id: verificationId,
      verification_type: verificationType,
      signature: localStorage.getItem("signature"),
    };
    try {
      const verifyOtpPayload = await requestHandler("verifyAddress", "post", data, "jwt_token");
      setLoading(false);
      if (verifyOtpPayload?.status === 200) {
        addNotification({
          title: "Success",
          message: verifyOtpPayload?.data?.message[0]?.msg,
          type: "success",
        });
        handleAddress(verifyOtpPayload);
        setVerificationModal(false);
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.message[0]?.msg,
        type: "danger",
      });
    };
  };

  const cryptoAddressHandler = (coin, token, address) => {
    if (coin && token != "Please select network token") {
      if (token.includes("ERC")) {
        const ercRegex = /^0x[a-fA-F0-9]{40}$/;
        if (ercRegex.test(address)) {
          setIsValid(true);
          setBtnProperty(false);
        }
        else {
          setBtnProperty(true);
          setIsValid(false);
        }
      }
      else if (token.includes("TRC")) {
        const trxRegex = /^T[1-9A-HJ-NP-Za-km-z]{33}$/;
        if (trxRegex.test(address)) {
          setBtnProperty(false);
          setIsValid(true);
        }
        else {
          setBtnProperty(true);
          setIsValid(false);
        }
      }
      else if (coin.includes("BNB")) {
        const bnbRegex = /^(0x)[0-9A-Fa-f]{40}$/
        if (bnbRegex.test(address)) {
          setIsValid(true);
          setBtnProperty(false);
        }
        else {
          setBtnProperty(true);
          setIsValid(false);
        }
      }
    }
    else if (coin && token === "Please select network token") {
      if (coin.includes("BTC")) {
        const bitcoinRegex = /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/;
        if (bitcoinRegex.test(address)) {
          setIsValid(true);
          setBtnProperty(false);
        }
        else {
          setIsValid(false);
          setBtnProperty(true);
        }
      }
      else if (coin.includes("ETH")) {
        const ethereumRegex = /^0x[a-fA-F0-9]{40}$/;
        if (ethereumRegex.test(address)) {
          setIsValid(true);
          setBtnProperty(false);
        }
        else {
          setIsValid(false);
          setBtnProperty(true);
        }
      }
      else if (coin.includes("XRP")) {
        const rippleRegex = /^([r])([1-9A-HJ-NP-Za-km-z]{24,34})$/gm;
        if (rippleRegex.test(address)) {
          setIsValid(true);
          setBtnProperty(false);
        }
        else {
          setIsValid(false);
          setBtnProperty(true);
        }
      }
      else if (coin.includes("LTC")) {
        const ltcRegex = /^(L|M|3|ltc1)[a-km-zA-HJ-NP-Z1-9]{26,}$|^(ltc1)[qpzry9x8gf2tvdw0s3jn54khce6mua7l]{8,}$/;
        if (ltcRegex.test(address)) {
          setIsValid(true);
          setBtnProperty(false);
        }
        else {
          setIsValid(false);
          setBtnProperty(true);
        }
      }
      else if (coin.includes("TRX")) {
        const trxRegex = /^T[1-9A-HJ-NP-Za-km-z]{33}$/;
        if (trxRegex.test(address)) {
          setIsValid(true);
          setBtnProperty(false);
        }
        else {
          setIsValid(false);
          setBtnProperty(true);
        }
      }
      else if (coin.includes("QTUM")) {
        const qtumRegex = /^(Q|q)[0-9a-zA-Z]{26,35}$/;
        if (qtumRegex.test(address)) {
          setIsValid(true);
          setBtnProperty(false);
        }
        else {
          setIsValid(false);
          setBtnProperty(true);
        }
      }
      else if (coin.includes("XLM")) {
        const stellarRegex = /^G[A-Z2-7]{55}$/;
        if (stellarRegex.test(address)) {
          setIsValid(true);
          setBtnProperty(false);
        }
        else {
          setIsValid(false);
          setBtnProperty(true);
        }
      }
      else if (coin.includes("SOL")) {
        const solanaRegex = /^(?!.*[IOio])[1-9A-HJ-NP-Za-km-z]{43}$/;
        if (solanaRegex.test(address)) {
          setIsValid(true);
          setBtnProperty(false);
        }
        else {
          setIsValid(false);
          setBtnProperty(true);
        }
      }
      else if (coin.includes("DOGE")) {
        const dogecoinRegex = /^(D|A|9)[a-km-zA-HJ-NP-Z1-9]{26,35}$|^(d|a|9)[qpzry9x8gf2tvdw0s3jn54khce6mua7l]{33,}$|^(D|A|9)[2-9A-HJ-NP-Za-km-z]{52,53}$/;
        if (dogecoinRegex.test(address)) {
          setIsValid(true);
          setBtnProperty(false);
        }
        else {
          setIsValid(false);
          setBtnProperty(true);
        }
      }
      else if (coin.includes("ADA")) {
        const cardanoRegex = /^(addr1|addr2)[a-km-zA-HJ-NP-Z1-9]{58}$/
        if (cardanoRegex.test(address)) {
          setIsValid(true);
          setBtnProperty(false);
        }
        else {
          setIsValid(false);
          setBtnProperty(true);
        }
      }
      else if ((coin === "Please select coin" && token === "Please select network token")) {
        setIsValid(true);
        setBtnProperty(false);
      }
      else {
        const ethereumRegex = /^0x[a-fA-F0-9]{40}$/;
        if (ethereumRegex.test(address)) {
          setIsValid(true);
          setBtnProperty(false);
        }
        else {
          setIsValid(false);
          setBtnProperty(true);
        }
      }
    }
  };

  const isValidCryptoAddressHandler = (coin, token, address) => {
    if (coin !== "Please select coin" && token === "Please select network token" && address != "") {
      cryptoAddressHandler(coin, token, address);
    }
    else if (coin !== "Please select coin" && token != "Please select network token" && address != "") {
      cryptoAddressHandler(coin, token, address);
    }
  };

  const archiveAddressHandler = async (address_id) => {
    setLoading(true);
    let data = {
      address_id: address_id,
      signature: localStorage.getItem("signature"),
    };
    try {
      const archiveAddressPayload = await requestHandler("archiveAddress", "post", data, "jwt_token");
      setLoading(false);
      setRefreshFlag(!refreshFlag)
      if (archiveAddressPayload && archiveAddressPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: archiveAddressPayload?.data?.message[0]?.msg,
          type: 'success'
        })
      }
    }
    catch (e) {
      setLoading(false);
      setRefreshFlag(!refreshFlag);
      addNotification({
        title: 'Error',
        message: "Something went wrong.",
        type: 'danger'
      })
    };
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.line}>

        <button className={styles.link}>
          <span>Addresses Listing</span>
          <Icon name="arrow-right" size="24" />
        </button>
        {/* <button className={styles.result}>
            <Icon name="search" size="20" />
          </button> */}

        <button
          className={cn("button", styles.button)}
          onClick={() => setVisibleTransfer(!visibleTransfer)}
        >
          Add New Address
        </button>
      </div>
      <div className={styles.list}>
        <div className={styles.row}>
          <div className={styles.col}>Coin</div>
          <div className={styles.col}>Address</div>
          <div className={styles.col}>Beneficiary Name</div>
          <div className={styles.col}>Address tag</div>
          <div className={styles.col}>Status</div>
          <div className={styles.col}>Date </div>
          <div className={styles.col}>Action</div>
        </div>
        {loading ? <WalletSkeleton rowCount={10} colCount={6} /> :
          allAddress?.data?.length > 0 ?
            <>
              {
                allAddress?.data?.map((x, index) => (
                  <Item
                    className={styles.item}
                    item={x}
                    key={index}
                    children={children}
                    handleAddress={handleAddress}
                    archiveAddressHandler={archiveAddressHandler}
                  />
                ))
              }
              {allAddress?.data?.length === (10 * pageNo) && <div className={styles.customButton}>
                <button className={cn("button-stroke button-small", styles.button)} onClick={() => handleLoadMore()} >
                  <span>Load more...</span>
                </button>
              </div>}
            </> :
            <div className={styles.btns}>
              <NoDataFound />
            </div>
        }
      </div>
      <Modal
        visible={visibleTransfer}
        onClose={() => {
          setVisibleTransfer(false);
          setIsValid(true);
        }}
      >
        <Address
          loading={loading}
          handleSubmit={handleSubmit}
          coinsData={coinsData}
          isValidCryptoAddressHandler={isValidCryptoAddressHandler}
          isValid={isValid}
          setIsValid={setIsValid}
          btnProperty={btnProperty}
          setBtnProperty={setBtnProperty}
          setLoading={setLoading}
        />
      </Modal>
      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
      >
        <Successfully
          closeModal={() => setVisible(false)}
        />
      </Modal>
      <Modal
        visible={verificationModal}
        onClose={() => setVerificationModal(false)}
      >
        <Code
          closeModal={() => setVerificationModal(false)}
          codeScreenHandler={codeScreenHandler}
          withdrawStatus={withdrawStatus}
          loading={loading}
          userStatus={userStatus}
        />
      </Modal>
    </div>
  );
};

export default Funds;
