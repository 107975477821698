import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./Address.module.sass";
import TextInput from "../TextInput";
import Dropdown from "../Dropdown";
import CustomDropdown from "../CustomDropdown";
import SimpleReactValidator from "simple-react-validator";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../Notification";
import LoaderScreen from "../LoaderScreen";

let coinOptions = []

const Address = ({
  handleSubmit,
  coinsData,
  loading,
  isValidCryptoAddressHandler,
  isValid,
  btnProperty,
  setBtnProperty,
  setIsValid,
  setLoading,
}) => {
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, setForceUpdate] = useState()
  const [coin, setCoin] = useState('');
  const [token, setToken] = useState("Please select network token");
  const [address, setAddress] = useState("");
  const [text, setText] = useState("Please select coin");
  const [ownership, setOwnership] = useState("");
  const [warning, setWarning] = useState();
  const [showFields, setShowFields] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [tag, setTag] = useState("");
  const [showTagField, setShowTagField] = useState();

  const [addressProof, setAddressProof] = useState();
  const [tokenOptions, setTokenOptions] = useState([]);
  const [selectedCoin, setSelectedCoin] = useState([]);

  const isUnstableCoin = selectedCoin.stableCoin === parseInt(process.env.REACT_APP_UNSTABLE_COIN);
  const isTextCoinSelected = text === "Please select coin";
  const isTokenNetworkSelected = token === "Please select network token";
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const disabled =
    (isUnstableCoin && isTextCoinSelected && isTokenNetworkSelected) ||
    isTextCoinSelected;

  useEffect(() => {
    if (coin) {
      setIsValid(true);
      setToken("Please select network token");
      setShowFields(true);
      setAddress("");
      coinsData?.data?.data.filter(x => x.id === coin).map(x => {
        setSelectedCoin(x);
        setTokenOptions(x.network_slug);
        x?.is_tag_enable === 1 ? setShowTagField(true) : setShowTagField(false);
      })
    } else {
      setSelectedCoin("");
      coinsData?.data?.data?.filter(x => x.symbol !== "eur" && x.enable_withdraw === parseInt(process.env.REACT_APP_WITHDRAW_ENABLE)).map(x => coinOptions.filter(a => a.id === x.id).length > 0 ? null : coinOptions.push({ "cId": x.id, "cValue": x.symbol?.toUpperCase() + ' (' + x.name?.toUpperCase() + ')' }));
    }
  }, [coin])

  const addressVerify = async (address) => {
    setLoading(true);
    if (address != '') {
      const data = {
        signature: localStorage.getItem('signature'),
        address: address,
        coinId: coin
      }
      try {
        const adressPayload = await requestHandler("checkInternalAddress", "post", data, "jwt_token");
        setLoading(false);
        if (adressPayload.data.message[0].status === false) {
          setBtnProperty(false)
          setWarning('');
          setShowMessage(false);
        }
        else {
          setShowMessage(true);
          setBtnProperty(true);
          setShowFields(false);
          addNotification({
            title: 'Alert',
            message: "This address belongs to bitdenex , Please transfer directly through crypto withdraw.",
            type: 'danger'
          });
        }
      }
      catch (error) {
        setLoading(false);
      }
    }
    else {
      setLoading(false);
      setBtnProperty(true)
    };
  };

  return (
    <>
      <div className={styles.transfer}>
        {loading && <LoaderScreen />}
        <div className={cn("h4", styles.title)}>
          Add New Address
        </div>
        <div className={styles.field}>
          <CustomDropdown
            className={styles.dropdown}
            label="select coin"
            value={coin}
            setValue={setCoin}
            text={text}
            setText={setText}
            options={coinOptions}
          // checkInternalAddress={true}
          // handleInternalAddress={() => { isValidCryptoAddressHandler(text, token, address) }}
          />
        </div>
        <span className={styles.danger}>{simpleValidator.current.message("coin", coin, "required")}</span>
        {selectedCoin.stableCoin === parseInt(process.env.REACT_APP_UNSTABLE_COIN) && selectedCoin ? (
          <div className={styles.field}>
            <Dropdown
              className={styles.dropdown}
              label="Select token network"
              value={token}
              setValue={setToken}
              options={tokenOptions}
            // addressValidatorFlag={true}
            // addressValidator={(value) => { isValidCryptoAddressHandler(text, value, address) }}
            />
          </div>
        ) : null}
        <span className={styles.danger}>{simpleValidator.current.message("token", token, "required")}</span>
        <div className={styles.box}>
          <TextInput
            className={styles.field}
            label="Enter Address"
            name="address"
            type="text"
            placeholder="Please enter address"
            note="Please be sure that the address is related to the coin/token that you are withdrawing."
            required
            value={address}
            onChange={e => { setAddress(e.target.value); }}
            // onBlur={() =>
            //   isValidCryptoAddressHandler(text, token, address)
            // }
            // disabled={disabled}
            onBlur={() => {
              addressVerify(address);
            }}
          />
        </div>
        <span className={styles.danger}>{simpleValidator.current.message("Address", address, "required")}</span>
        {
          (showTagField && showFields) &&
          <div className={styles.box}>
            <TextInput
              className={styles.field}
              label="Enter Tag"
              name="tag"
              type="text"
              placeholder="Please enter tag"
              value={tag}
              onChange={e => { setTag(e.target.value); }}
            />
          </div>
        }
        {
          showMessage &&
          <div className={styles.customWrap}>
            <div className={styles.info}>
              Note: This address belongs to Bitdenex. No need to upload the proof for this address.
            </div>
          </div>
        }
        {/* {!isValid &&
          <div className={styles.customWrap}>
            <div className={styles.addressInfo}>
              Note: The address is invalid!
            </div>
          </div>
          // :() => { addressVerify()
        } */}

        {warning}
        {
          showFields &&
          <>
            <div className={styles.box} >
              <TextInput
                className={styles.field}
                label="Name Of Exchange Or Ledger"
                name="exchangeName"
                type="text"
                placeholder="Please enter name of exchange"
                //note="Please be sure that the address is related to the coin/token that you are withdrawing."
                required
                onChange={e => { setOwnership(e.target.value) }}
              // onBlur={() => { validateExchangeField() }}
              />
            </div>
            <span className={styles.danger}>{simpleValidator.current.message("exchange", ownership, "required")}</span>
            <div className={styles.box}>
              <TextInput
                className={styles.field}
                label="Scan With Proof Of Ownership"
                name="addressProof"
                type="file"
                note="Use Screenshot of your address where it exists."
                required
                // value={addressProof}
                onChange={e => setAddressProof(e.target.files[0])}
              />
            </div>
            <span className={styles.danger}> {simpleValidator.current.message("AddressProof", addressProof, "required")}</span>
            <div className={styles.wrap}>
              <div className={styles.info}>
                Provide a photo that shows your ID and your wallet or exchange account with the cryptocurrency address clearly visible.
                Both your Id and address need to be visible in the same photo.
              </div>
            </div>
            <div className={styles.box}>
              <TextInput
                className={styles.field}
                label="Beneficiary's First Name"
                name="fname"
                type="text"
                required
                value={firstName}
                placeholder={"Please enter beneficiary's first name"}
                onChange={(e) => { setFirstName(e.target.value) }}
              />
            </div>
            <div className={styles.box}>
              <TextInput
                className={styles.field}
                label="Beneficiary's Last Name"
                name="lname"
                type="text"
                required
                value={lastName}
                onChange={(e) => { setLastName(e.target.value) }}
                placeholder={"Please enter beneficiary's last name"}
              />
            </div>
          </>
        }
        <button className={cn("button", styles.button)}
          // disabled={btnProperty}
          onClick={() => {
            if (!showFields) {
              if (selectedCoin.stableCoin !== parseInt(process.env.REACT_APP_UNSTABLE_COIN)) {
                if (text !== "Please select coin" && address !== "") {
                  handleSubmit(coin, token, address, ownership, addressProof);
                }
                else {
                  addNotification({
                    title: "Alert",
                    message: "Please fill all the fields",
                    type: "danger",
                  })
                }
              }
              else if (selectedCoin.stableCoin === parseInt(process.env.REACT_APP_UNSTABLE_COIN)) {
                if (coin !== "Please select coin" && address !== "") {
                  if (token !== "Please select network token") {
                    handleSubmit(coin, token, address, ownership, addressProof);
                  }
                  else {
                    addNotification({
                      title: "Alert",
                      message: "Please select a network",
                      type: "danger",
                    })
                  }
                }
              }
            }
            else if (showFields) {
              if (selectedCoin.stableCoin === parseInt(process.env.REACT_APP_UNSTABLE_COIN)) {
                if (showTagField) {
                  if (coin !== "Please select coin" && address !== "" && ownership !== "" && (addressProof !== null || addressProof !== undefined) && tag !== "" && firstName !== "" && lastName !== "") {
                    if (token !== "Please select network token") {
                      handleSubmit(coin, token, address, ownership, addressProof, tag, firstName, lastName);
                    }
                    else {
                      addNotification({
                        title: "Alert",
                        message: "Please select a network",
                        type: "danger",
                      })
                    }
                  }
                  else {
                    addNotification({
                      title: "Alert",
                      message: "Please fill all the fields",
                      type: "danger",
                    })
                  }
                }
                else {
                  if (coin !== "Please select coin" && address !== "" && ownership !== "" && (addressProof !== null || addressProof !== undefined) && firstName !== "" && lastName !== "") {
                    if (token !== "Please select network token") {
                      handleSubmit(coin, token, address, ownership, addressProof, null, firstName, lastName);
                    }
                    else {
                      addNotification({
                        title: "Alert",
                        message: "Please select a network",
                        type: "danger",
                      })
                    }
                  }
                  else {
                    addNotification({
                      title: "Alert",
                      message: "Please fill all the fields",
                      type: "danger",
                    })
                  }
                }
              }
              else if (selectedCoin.stableCoin !== parseInt(process.env.REACT_APP_UNSTABLE_COIN)) {
                if (showTagField) {
                  if (text !== "Please select coin" && address !== "" && ownership !== "" && (addressProof !== null && addressProof !== undefined) && tag != "" && firstName !== "" && lastName !== "") {
                    handleSubmit(coin, token, address, ownership, addressProof, tag, firstName, lastName);
                  }
                  else {
                    addNotification({
                      title: "Alert",
                      message: "Please fill all the fields",
                      type: "danger",
                    })
                  }
                }
                else {
                  if (text !== "Please select coin" && address !== "" && ownership !== "" && (addressProof !== null && addressProof !== undefined) && firstName !== "" && lastName !== "") {
                    handleSubmit(coin, token, address, ownership, addressProof, null, firstName, lastName);
                  }
                  else {
                    addNotification({
                      title: "Alert",
                      message: "Please fill all the fields",
                      type: "danger",
                    })
                  }
                }
              }
            }

            // if (simpleValidator.current.allValid()) {
            //   console.log("hey");
            //   if (selectedCoin.stableCoin === parseInt(process.env.REACT_APP_UNSTABLE_COIN) && token === "Please select network token") {
            //     addNotification({
            //       title: "Alert",
            //       message: "Please select network",
            //       type: "danger",
            //     })
            //   }
            //   else {
            //     handleSubmit(coin, token, address, ownership, addressProof);
            //   }
            // }
            // else {
            //   simpleValidator.current.showMessages();
            //   setForceUpdate(1);
            // }
          }}>Submit</button>
      </div >
    </>
  );
};

export default Address;
