
import Item from "./Item";
import cn from "classnames";
import styles from './Funds.module.sass'
import NoDataFound from "../../../components/NoDataFound";
import OrderSkeleton from "../../../components/Skeleton/OrderSkeleton";
import CustomDropdown from "../../../components/CustomDropdown";
import Dropdown from "../../../components/Dropdown";

const Funds = ({
  completeOrders,
  children,
  loading,
  active,
  currencyOptions,
  currencyValue,
  setCurrencyValue,
  marketValue,
  setMarketValue,
  markets,
  currencyText,
  setCurrencyText,
  orderTypeValue,
  orderTypeValues,
  setOrderTypeValue,
  filteredOrders
}) => {

  return (
    <div className={styles.wrap}>
      <div className={styles.line}>
        <div className={styles.dropdown}>
          <CustomDropdown
            value={currencyValue}
            setValue={setCurrencyValue}
            text={`Side: ${currencyText}`}
            setText={setCurrencyText}
            options={currencyOptions}
            className={styles.dropdownList}
          />
        </div>
        <div className={styles.dropdown}>
          <Dropdown
            value={`Pair: ${marketValue}`}
            setValue={setMarketValue}
            options={markets}
            className={styles.dropdownList}
          />
        </div>
        <div className={styles.dropdown}>
          <Dropdown
            value={`Type: ${orderTypeValue}`}
            setValue={setOrderTypeValue}
            options={orderTypeValues}
            className={styles.dropdownList}
          />
        </div>
      </div>
      <div className={styles.list}>
        <div className={styles.row}>
          <div className={styles.col}>ID</div>
          <div className={styles.col}>Market</div>
          <div className={styles.col}>Type</div>
          <div className={styles.col}>Price</div>
          <div className={styles.col}>Amount</div>
          <div className={styles.col}>Value</div>
          <div className={styles.col}>Fee</div>
          <div className={styles.col}>Status</div>
          <div className={styles.col}>Date</div>
        </div>
        {loading ?
          <OrderSkeleton rowCount={10} colCount={9} />
          :
          filteredOrders?.length > 0 ?
            <>
              {filteredOrders?.map((x, index) => (
                <Item
                  className={styles.item}
                  item={x}
                  key={index}
                  children={children}
                  active={active}
                />
              ))}
            </>
            :
            <div className={styles.btns}>
              <NoDataFound />
            </div>
        }

      </div>
    </div>
  );
};

export default Funds;
