import React, { useState } from "react";
import cn from "classnames";
import styles from "./Balance.module.sass";
import Icon from "../../../components/Icon";
import { getDigitsAfterDecimal } from "../../../components/helper";

const sorting = [
    {
        color1: "#FF6838",
        color2: "#B1B5C3",
        color3: "#58BD7D",
    },
    {
        color1: "#B1B5C3",
        color2: "#B1B5C3",
        color3: "#58BD7D",
    },
    {
        color1: "#FF6838",
        color2: "#B1B5C3",
        color3: "#B1B5C3",
    },
];

const Balance = ({
    buy,
    sell,
    orderData,
    currentMarketPrice,
    priceColor
}) => {
    const priceMax = sell.length > 0 ? Math.max(...sell.map((i) => i.fiatPrice)) : parseFloat(orderData?.current_market_details?.[0]?.currentMarketPrice);

    const [showOption, setshowOption] = useState(0);
    const [limitOption, setLimitOption] = useState(15);

    const topBtnHandler = (index) => {
        setshowOption(index)
        if (index === 1 || index === 2) {
            setLimitOption(30);
        }
        else {
            setLimitOption(15);
        }
    };

    const getTranslateValue = (remFiat) => {
        let calcValue = (100 * (parseFloat(remFiat) / parseFloat(priceMax)));
        if (calcValue >= 100) {
            return 100;
        }
        else {
            return calcValue;
        }
    };

    return (
        <div className={styles.balance}>
            <div className={styles.head}>
                <div className={styles.sorting}>
                    {sorting.map((x, index) => (
                        <button
                            className={cn(styles.direction, { [styles.active]: index === showOption })}
                            key={index} onClick={() => topBtnHandler(index)}
                        >
                            <span style={{ backgroundColor: x.color1 }}></span>
                            <span style={{ backgroundColor: x.color2 }}></span>
                            <span style={{ backgroundColor: x.color3 }}></span>
                        </button>
                    ))}
                </div>
            </div>
            <div className={styles.top}>
                <div className={styles.price}>Price({orderData?.current_market_details?.[0]?.currency?.toUpperCase()})</div>
                <div className={styles.amount}>Amount({orderData?.current_market_details?.[0]?.coin?.toUpperCase()})</div>
                <div className={styles.total}>Total({orderData?.current_market_details?.[0]?.currency?.toUpperCase()})  </div>
            </div>
            <div className={styles.list}>
                {(showOption === 0 || showOption === 2) && (
                    sell?.slice(0, limitOption).sort((a, b) => parseFloat(a.fiatPrice) < parseFloat(b.fiatPrice) ? 1 : -1).map((x, index) =>
                        <div className={cn(styles.item, { [styles.negative]: true })} key={index}>
                            <div className={styles.price}>{getDigitsAfterDecimal(x.fiatPrice, x.priceDecimalPrecision)} </div>
                            <div className={styles.amount}> {getDigitsAfterDecimal(x.remcrypto, x.amountDecimalPrecision)}</div>
                            <div className={styles.total}>{getDigitsAfterDecimal(x.remfiat, 2)} </div>
                            {/* <div className={styles.progressAsk} style={{ transform: "translateX(-" + getTranslateValue(x.remfiat) + "%)", left: "100%" }}></div> */}
                            <div
                                className={styles.line}
                                style={{ width: `${x.normalizedVolume * 100}%` }}
                            ></div>
                        </div>
                    )
                )}
                <div className={cn(styles.statistics, priceColor === "bottom" ? styles.negative : styles.positive)}>
                    <div className={styles.currency}>
                        {
                            currentMarketPrice === null || currentMarketPrice === undefined || currentMarketPrice === ""
                                ?
                                orderData?.length === 0 || orderData === null || orderData === undefined ? "---" :
                                    getDigitsAfterDecimal(orderData?.current_market_details?.[0]?.currentMarketPrice, orderData?.current_market_details?.[0]?.priceDecimalPrecision)
                                :
                                getDigitsAfterDecimal(currentMarketPrice, orderData?.current_market_details?.[0]?.priceDecimalPrecision)
                        }
                    </div>
                    <Icon fill={priceColor === "bottom" ? styles.negative : styles.positive} name={`arrow-${priceColor}`} size="16" />
                </div>

                {(showOption === 0 || showOption === 1) && (
                    buy?.slice(0, limitOption)?.sort((a, b) => parseFloat(a.fiatPrice) < parseFloat(b.fiatPrice) ? 1 : -1)?.map((x, index) =>
                        <div className={cn(styles.item, { [styles.positive]: true })} key={index} >
                            <div className={styles.price}>{getDigitsAfterDecimal(x.fiatPrice, x.priceDecimalPrecision)}</div>
                            <div className={styles.amount}>{getDigitsAfterDecimal(x.remcrypto, x.amountDecimalPrecision)}</div>
                            <div className={styles.total}>{getDigitsAfterDecimal(x.remfiat, 2)}</div>
                            {/* <div className={styles.progressBid} style={{ transform: "translateX(-" + getTranslateValue(x.remfiat) + "%)", left: "100%" }}></div> */}
                            <div
                                className={styles.line}
                                style={{ width: `${x.normalizedVolume * 100}%` }}
                            ></div>
                        </div>
                    )
                )}
            </div>
        </div>
    );
};

export default Balance;
