import React from 'react';
import styles from "./WhatsAppSupport.module.sass";

const WhatsAppSupport = ({ message, phoneNumber }) => {

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;

    return (
        <div className={styles.whatsappButtonContainer}>
            <a href={whatsappUrl} target="_blank" rel="noopener noreferrer" className={styles.whatsappLink}>
                <img
                    src="/images/content/whatsappIcon.png"
                    alt="WhatsApp"
                    className={styles.whatsappIcon}
                />
            </a>
        </div>
    );
};

export default WhatsAppSupport;
