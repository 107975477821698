import historyProvider from "./historyProvider";
let _subs = [];
const _unsubs = [];
export default {
  async subscribeBars(symbolInfo, resolution, updateCb, uid, resetCache) {
    const channelString = createChannelString(symbolInfo);
    // Add the new subscription to _subs
    const newSub = {
      channelString,
      uid,
      resolution,
      symbolInfo,
      lastBar: historyProvider.history[symbolInfo.name]?.lastBar || null,
      listener: updateCb,
    };
    _subs.push(newSub);
    // Fetch socket data and subscribe to the new symbol
    var data = await historyProvider.socketFunction();
    var socket = data.socket;
    const channel = symbolInfo.name.replace(/-/g, '_');
    socket.send(JSON.stringify({
      action: 'subscribe',
      channel: channel,
      resolution: resolution,
      endTime: null
    }));
  },
  async unsubscribeBars(uid) {
    _subs = [];
    var data = await historyProvider.socketFunction();
    var socket = data.socket;
    var channel = data.subChannel;
    socket.send(JSON.stringify({
      action: 'unsubscribe',
      channel: channel[0].symbol_name,
      resolution: channel[0].resolution,
      endTime: null
    }));
  },
};
export function updateChart(e) {
  const data = {
    ts: e.ts,
    price: parseFloat(e.price),
    total: e.total,
    open: e.open,
    high: e.high,
    low: e.low,
    close: e.close
  };
  _subs.forEach(sub => {
    const updatedBar = updateBar(data, sub);
    if (updatedBar) {
      sub.listener(updatedBar); // Update the chart with the new bar
      sub.lastBar = updatedBar; // Store the last updated bar
    }
  });
}
function updateBar(data, sub) {
  const lastBar = sub.lastBar || {};
  let updatedBar;
  let roundedTime = data.ts * 1000;
  // Skip out-of-order bars
  if (roundedTime < (lastBar.time || 0)) {
    return null; // Skip this bar if it's out of order
  }
  updatedBar = {
    time: roundedTime,
    open: lastBar.close || data.open,
    high: data.high,
    low: data.low,
    close: data.close,
    volume: parseFloat(data.total),
  };
  return updatedBar;
}
function createChannelString(symbolInfo) {
  const [exchange, symbol] = symbolInfo.full_name.split(":")[1].split("-");
  return `${exchange}~${symbol}`;
}
export function last(array) {
  return array[array.length - 1];
}
export { _unsubs, _subs }