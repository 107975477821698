import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";

const Main = ({ }) => {
  return (
    <div className={styles.main}>
      <h4 className={cn("h4", styles.title)}>Orders History</h4>
    </div>
  );
};

export default Main;
