import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Main.module.sass";
import PDF from "./BDNX_WHITEPAPER.pdf";

const Main = ({ }) => {

  return (
    <div className={cn("section-mb0", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <h2 className={cn("h2", styles.title)}>Bitdenex Token (BDNX)</h2>
          <h4 className={cn("h4", styles.title)}>The key to unlock various benefits.</h4>
          <div className={styles.text}>
            Connecting your digital assets and NFT's to unlock Web3 opportunities.
          </div>
          <div className={styles.text}>
            <Link className={cn("button", styles.button)} to={localStorage.getItem("signature") ? "/exchange/BDNX-EUR" : "/sign-in"}>
              Get BDNX
            </Link>
            {/* <Link
              className={cn("button", styles.button)}
              to={"/staking"}
            >
              Stake BDNX
            </Link> */}
            <a href={PDF} target="_blank" rel="noreferrer" className={cn("button", styles.button)}>
              BDNX whitepaper
            </a>
          </div>
        </div>
        <div className={styles.bg}>
          <img
            // srcSet="/images/content/legal/legal-main@2x.png 2x"
            src="/images/content/token.png"
            alt="Cards"
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
