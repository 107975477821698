import cn from "classnames";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userStatusCreator } from "../../../actions/getUserStatus";
import requestHandler from "../../../actions/httpClient";
import Deposit from "../../../components/Deposit";
import LoaderScreen from "../../../components/LoaderScreen";
import Modal from "../../../components/Modal";
import { addNotification } from "../../../components/Notification";
import TradeSkeleton from "../../../components/Skeleton/TradeSkeleton";
import Withdraw from "../../../components/Withdraw";
import styles from "./AssetBalances.module.sass";
import Item from "./Item";
import MarginTradingTransfer from "../../../components/FundTransfer";

const AssetBalances = ({
  userStatus,
  walletData,
  search,
  hideZero,
  loading,
  withdrawLoader,
  setWithdrawLoader,
  activeTab,
  visibleTransfer,
  setVisibleTransfer
}) => {
  const { coinsData } = useSelector((state) => { return state.coins })
  const [selectedCoin, setSelectedCoin] = useState([])
  const [imageUrl, setImageUrl] = useState()
  const [loader, setLoader] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0);
  const [visibleDeposit, setVisibleDeposit] = useState(false);
  const [visibleWithdraw, setVisibleWithdraw] = useState(false);
  const [visibleSuccessfully, setVisibleSuccessfully] = useState(true);
  const [walletAddressData, setWalletAddressData] = useState([])
  const [newCoinGeneratedID, setNewCoinGeneratedId] = useState()
  const [depositLoadting, setDepositLoading] = useState(false)
  const [fireBlockId, setFireBlockId] = useState([])
  const [networkSlug, setNetworkSlug] = useState([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const [visibleTransfer, setVisibleTransfer] = useState(false);

  const withdrawHandler = (x, img_url,) => {
    if (userStatus?.role === process.env.REACT_APP_CORPORATE_ROLE) {
      if (x.coinId !== parseInt(process.env.REACT_APP_EURO_ID)) {
        setSelectedCoin(x)
        setImageUrl(img_url)
        getWalletAddressCreator(x)
      } else {
        navigate('/withdraw-fiat')
      }
    }
    else {
      if (userStatus?.kyc_verification !== parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE)) {
        navigate('/user-kyc-verification')
      } else {
        if (x.coinId !== parseInt(process.env.REACT_APP_EURO_ID)) {
          setSelectedCoin(x)
          setImageUrl(img_url)
          getWalletAddressCreator(x)
        } else {
          navigate('/withdraw-fiat')
        }
      }
    }
  }

  const depositHandler = (x, img_url,) => {
    if (userStatus?.role === process.env.REACT_APP_CORPORATE_ROLE) {
      setNetworkSlug([])
      if (x.coinId !== parseInt(process.env.REACT_APP_EURO_ID)) {
        if (x.stableCoin === parseInt(process.env.REACT_APP_UNSTABLE_COIN) && x.stableCoin) {
          getFireblockNetworkId(x)
        }
        setVisibleDeposit(true)
        setSelectedCoin(x)
        setImageUrl(img_url)
      } else {
        navigate('/deposit-fiat')
      }
    }
    else {
      if (userStatus?.kyc_verification !== parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE)) {
        navigate('/user-kyc-verification')
      } else {
        setNetworkSlug([])
        if (x.coinId !== parseInt(process.env.REACT_APP_EURO_ID)) {
          if (x.stableCoin === parseInt(process.env.REACT_APP_UNSTABLE_COIN) && x.stableCoin) {
            getFireblockNetworkId(x)
          }
          setVisibleDeposit(true)
          setSelectedCoin(x)
          setImageUrl(img_url)
        } else {
          navigate('/deposit-fiat')
        }
      }
    }
  }

  const getFireblockNetworkId = async (x) => {
    setLoader(true)
    try {
      let data = {
        signature: localStorage.getItem("signature"),
        coinId: x.coinId
      };
      const payload = await requestHandler("getFireblockNetworkId", "post", data, "jwt_token");
      setVisibleDeposit(true)

      setNetworkSlug(payload.data.data[0].network_slug)
      setFireBlockId(payload.data.data[0].fireblock_network_id)

      // if (x.coinId === 3) {
      //   let filteredNetworkSlug = payload.data.data[0].network_slug.filter(function(e) { return e !== 'TRC20' })
      //   setNetworkSlug(filteredNetworkSlug)
      //   let filteredFireblockId = payload.data.data[0].network_slug.filter(function(e) { return e !== 'TRX_USDT_S2UZ' })
      //   setFireBlockId(filteredFireblockId)
      // } else {
      //   setNetworkSlug(payload.data.data[0].network_slug)
      //   setFireBlockId(payload.data.data[0].fireblock_network_id)
      // }
      setLoader(false)
    }
    catch (e) {
      setLoader(false)
    };
  }

  const getWalletAddressCreator = async (x) => {
    setLoader(true)
    try {
      let data = {
        signature: localStorage.getItem("signature"),
        coinId: x.coinId
      };
      const payload = await requestHandler("getAddress", "post", data, "jwt_token");
      setLoader(false)
      setWalletAddressData(payload.data.data)
      //opens modal after all data set        
      setVisibleWithdraw(true);
    }
    catch (e) {
      setVisibleWithdraw(true);
      setLoader(false)
    };
  };

  const newWithdrawCreator = async (data) => {
    try {
      setLoader(true)
      const payload = await requestHandler("newWithdrawCrypto", "post", data, "jwt_token", "file");
      if (payload.status === 201) {
        setLoader(false)
        setNewCoinGeneratedId(payload?.data?.message?.data.id)
        setVisibleSuccessfully(false);
        setActiveIndex(1);
      }
    }
    catch (e) {
      setVisibleSuccessfully(false);
      setVisibleWithdraw(false);
      setLoader(false)
      addNotification({
        title: "Error",
        message: e?.data?.message[0].msg,
        type: "danger"
      });
    };
  };

  const verifyOtpWithdrawCreator = async (data) => {
    try {
      setLoader(true)
      const payload = await requestHandler("verifyWithdrawCrypto", "post", data, "jwt_token");
      setLoader(false)
      if (payload.status === 200) {
        dispatch(userStatusCreator());
        addNotification({
          title: "Success",
          message: payload.data.message[0].msg,
          type: "Success"
        });
        setActiveIndex(2);
      };
    }
    catch (e) {
      setLoader(false)
      addNotification({
        title: "Error",
        message: e?.data?.message[0].msg,
        type: "danger"
      });
    };
  };

  const cryptoAddressGenerater = async (coinId, fId) => {
    setDepositLoading(true)
    const data = {
      coinId: coinId,
      fireBlock_network_id: fId,
      signature: localStorage.getItem("signature"),
    }
    try {
      const payload = await requestHandler("generateCryptoAddress", "post", data, "jwt_token");
      setDepositLoading(false)
      if (payload.status === 200) {
        dispatch(userStatusCreator());
        addNotification({
          title: "Success",
          message: payload.data.message[0].msg,
          type: "Success"
        });
        setVisibleDeposit(false)
      };
    }
    catch (e) {
      setDepositLoading(false)
      addNotification({
        title: "Error",
        message: e?.data?.message[0].msg,
        type: "danger"
      });
    };
  };

  const codeScreenHandler = (firstField, secondField, thirdField, fourthField, fifthField, sixthField, verificationType) => {
    const code = firstField + secondField + thirdField + fourthField + fifthField + sixthField;
    let data = {
      code: code,
      id: newCoinGeneratedID,
      verification_type: verificationType,
      signature: localStorage.getItem("signature"),
    };
    verifyOtpWithdrawCreator(data)
  };

  const withdrawNewAddressHandler = (coin, withdrawAddress, ownership, addressProof, enteredAmount, token, tag, firstName, lastName) => {
    let data = {
      signature: localStorage.getItem("signature"),
      amount: enteredAmount,
      coinId: coin,
      ownership: ownership,
      scanOwnership: addressProof,
      network: token ? token : null,
      address: withdrawAddress?.trim(),
      tag: tag,
      fname: firstName,
      lname: lastName
    };
    newWithdrawCreator(data);
  };

  const withdrawAddressHandler = async (coinId, amount, address, firstName, lastName, addressDetails) => {
    if (addressDetails?.fname === null) {
      setLoader(true);
      let userData = {
        fname: firstName,
        lname: lastName,
        id: addressDetails?.id,
        signature: localStorage.getItem("signature"),
      };
      try {
        const updateUserDetailsHandler = await requestHandler("updateAddress", "post", userData, "jwt_token");
        setLoader(false);
        if (updateUserDetailsHandler.status === 200) {
          let data = {
            coinId: coinId,
            amount: amount,
            address_id: address,
            signature: localStorage.getItem("signature"),
          };
          newWithdrawCreator(data);
        }
        else {
          addNotification({
            title: "Error",
            message: "Something went wrong, please try again later.",
            type: "danger",
          });
        }
      }
      catch (e) {
        setLoader(false);
      }
    }
    else {
      let data = {
        coinId: coinId,
        amount: amount,
        address_id: address,
        signature: localStorage.getItem("signature"),
      };
      newWithdrawCreator(data);
    }
  };

  // const withdrawAddressHandler = async (coinId, amount, address) => {
  //   let data = {
  //     coinId: coinId,
  //     amount: amount,
  //     address_id: address,
  //     signature: localStorage.getItem("signature"),
  //   };
  //   newWithdrawCreator(data);
  // };

  return (
    <div className={styles.wrap}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Asset</div>
          <div className={styles.col}>Total balance</div>
          <div className={styles.col}>Euro balance</div>
          {activeTab?.toLowerCase() === "spot" && <div className={styles.col}>Instant Trade</div>}
          {activeTab?.toLowerCase() === "spot" ? <div className={styles.col}>Action</div>
            :
            <div className={styles.col}>Trade</div>
          }
        </div>
        {loading ? <TradeSkeleton rowCount={25} colCount={5} />
          :
          activeTab?.toLowerCase() === "spot" ?
            userStatus?.wallet?.balances
              ?.sort((a, b) => {
                if (a.symbol?.toLowerCase() === 'eur') return -1;
                if (b.symbol?.toLowerCase() === 'eur') return 1;

                return parseFloat(b?.euro_balance) - parseFloat(a?.euro_balance)
              }
              )
              ?.map((item, index) => {
                return hideZero === 0 && parseFloat(item.balance) === 0 || search !== "" && item.name.toLowerCase().indexOf(search.toLowerCase()) === -1 ? null
                  :
                  (<Item
                    item={item}
                    key={index}
                    walletData={walletData}
                    userStatus={userStatus}
                    withdrawHandler={withdrawHandler}
                    depositHandler={depositHandler}
                    activeTab={activeTab}
                  />
                  );
              })
            :
            userStatus?.stake_wallet?.balances?.filter(x => x?.symbol?.toLowerCase() === "bdnx")
              ?.sort((a, b) => parseFloat(b?.euro_balance) - parseFloat(a?.euro_balance))
              ?.map((item, index) => {
                return hideZero === 0 && parseFloat(item.balance) === 0 || search !== "" && item.name.toLowerCase().indexOf(search.toLowerCase()) === -1 ? null
                  :
                  (
                    <Item
                      item={item}
                      key={index}
                      walletData={walletData}
                      userStatus={userStatus}
                      withdrawHandler={withdrawHandler}
                      depositHandler={depositHandler}
                      activeTab={activeTab}
                      setVisibleTransfer={setVisibleTransfer}
                    />
                  )
              })
        }
        {/* {loading ? <TradeSkeleton rowCount={25} colCount={5} />
          :
          walletData.balances?.map((item, index) => {
            return hideZero === 0 && parseFloat(item.balance) === 0 || search !== "" && item.name.toLowerCase().indexOf(search.toLowerCase()) === -1 ? null
              :
              <Item
                item={item}
                key={index}
                walletData={walletData}
                userStatus={userStatus}
                withdrawHandler={withdrawHandler}
                depositHandler={depositHandler}
                activeTab={activeTab}
              />
          })} */}
      </div>
      <Modal visible={visibleDeposit} onClose={() => setVisibleDeposit(false)}>
        <Deposit state={selectedCoin} imageUrl={imageUrl}
          userStatus={userStatus}
          cryptoAddressGenerater={cryptoAddressGenerater}
          depositLoadting={depositLoadting}
          networkSlug={networkSlug}
          fireBlockId={fireBlockId}
          loader={loader}
        />
      </Modal>
      {loader && <LoaderScreen />}
      <Modal
        visible={visibleWithdraw}
        onClose={() => { setVisibleWithdraw(false); setActiveIndex(0) }
        }>
        <Withdraw
          activeIndex={activeIndex}
          visibleSuccessfully={visibleSuccessfully}
          codeScreenHandler={codeScreenHandler}
          state={selectedCoin}
          walletAddressData={walletAddressData}
          withdrawAddressHandler={withdrawAddressHandler}
          coinsData={coinsData}
          coinOptions={coinsData?.data?.data}
          withdrawNewAddressHandler={withdrawNewAddressHandler}
          setVisibleSuccessfully={setVisibleSuccessfully}
          userStatus={userStatus}
          loader={loader}
        />
      </Modal>
      <Modal
        visible={visibleTransfer}
        onClose={() => setVisibleTransfer(false)}
      >
        <MarginTradingTransfer
          setVisibleFundTransfer={setVisibleTransfer}
        />
      </Modal>
    </div>
  );
};

export default AssetBalances;
