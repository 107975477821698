import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import { toLocaleStringDateFormat, getDigitsAfterDecimal } from "../../../../components/helper";
import History from "../../../../components/History";

const Item = ({ item, children, active }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className={cn(styles.item, { [styles.active]: visible })}>
        <div className={styles.row} onClick={() => setVisible(!visible)} >
          <div className={styles.col}>
            <div className={styles.info}><small>{item.orderId}</small></div>
          </div>
          <div className={styles.col}>{item?.slug?.toUpperCase()}</div>
          <div className={styles.col}>
            {(item?.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER)) && (
              <div className={styles.positive}>Buy/{item?.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) ? "Limit" : "Market"}</div>
            )}
            {(item?.orderType === parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER)) && (
              <div className={styles.negative}>Sell/{item?.orderType === parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER) ? "Limit" : "Market"}</div>
            )}
          </div>
          <div className={styles.col}>
            {(item.orderType == parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER)) ? (
              <div className={styles.positive}>{getDigitsAfterDecimal(item.price, item.priceDecimalPrecision)} <small> {item?.slug.split("-")[1].toUpperCase()} </small> </div>
            ) :
              (<div className={styles.negative}>{getDigitsAfterDecimal(item.price, item.priceDecimalPrecision)} <small> {item?.slug.split("-")[1].toUpperCase()} </small> </div>)}
          </div>
          <div className={styles.col}>
            {item?.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER) ?
              <div className={styles.positive}>{getDigitsAfterDecimal(item.crypto_amount, item.amountDecimalPrecision)}<small> {item?.coin?.toUpperCase()}</small></div>
              :
              <div className={styles.negative}>{getDigitsAfterDecimal(item.crypto_amount, item.amountDecimalPrecision)}<small> {item?.coin?.toUpperCase()}</small></div>
            }
          </div>
          <div className={styles.col}>
            {item?.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER) ?
              <div className={styles.positive}>{getDigitsAfterDecimal(item.fiat_amount, item.priceDecimalPrecision)}<small> {item?.currency?.toUpperCase()}</small></div>
              :
              <div className={styles.negative}>{getDigitsAfterDecimal(item.fiat_amount, item.priceDecimalPrecision)}<small> {item?.currency?.toUpperCase()}</small></div>
            }
          </div>
          <div className={styles.col}>
            {item?.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER) ?
              <span>{getDigitsAfterDecimal(item?.fiatFee, item?.priceDecimalPrecision)} <small>{item?.currency?.toUpperCase()}</small></span>
              :
              <span>{getDigitsAfterDecimal(item?.cryptoFee, item?.amountDecimalPrecision)} <small>{item?.coin?.toUpperCase()}</small></span>
            }
          </div>
          <div className={styles.col}>
            <div className={cn("category-green", styles.complete)}>Filled</div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}><small>{toLocaleStringDateFormat(item.updatedAt)}</small></div>
          </div>
        </div>
        <div className={styles.btns}>
          <History
            orders={item.order_matching_history}
            coin={item?.coin}
            currency={item?.currency}
            decimalPrecison={item?.priceDecimalPrecision}
            amountPrecision={item?.amountDecimalPrecision}
            active={active}
            item={item}
          />
        </div>
      </div>
    </>
  );
};

export default Item;
