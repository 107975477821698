import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";
import Icon from "../../../components/Icon";
import { getDigitsAfterDecimal } from "../../../components/helper";

const Main = ({
    selectedMarket,
    currentMarketPrice,
    priceColor,
    orderData
}) => {

    return (
        <div className={styles.main}>
            <div className={styles.details}>
                <div className={styles.box}>
                    <div className={styles.line}>
                        <div className={styles.info}>
                            {Object.keys(selectedMarket).length ? selectedMarket?.slug?.toUpperCase() : "..."}
                        </div>
                        <div className={cn("category-green", styles.category)}></div>
                    </div>
                    <div className={styles.content}>{Object.keys(selectedMarket).length ? selectedMarket?.name?.toUpperCase() : "..."}</div>
                </div>
                <div className={styles.box}>
                    <div className={cn(styles.statistics, priceColor === "bottom" ? styles.negative : styles.positive)}>
                        <div className={styles.currency}>
                            {
                                currentMarketPrice === null || currentMarketPrice === undefined || currentMarketPrice === ""
                                    ?
                                    orderData?.length === 0 || orderData === null || orderData === undefined ? "---" :
                                        getDigitsAfterDecimal(orderData?.current_market_details?.[0]?.currentMarketPrice, orderData?.current_market_details?.[0]?.priceDecimalPrecision)
                                    :
                                    getDigitsAfterDecimal(currentMarketPrice, orderData?.current_market_details?.[0]?.priceDecimalPrecision)
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.list}>
                <div className={styles.item}>
                    <div className={styles.subtitle}>
                        <Icon name="clock" size="16" />
                        24h change
                    </div>
                    <div className={styles.content} style={{ color: parseFloat(selectedMarket?.dayChange) > 0 ? '#00C076' : '#FF6838' }} >
                        {Object.keys(selectedMarket).length ? getDigitsAfterDecimal(selectedMarket.dayChange, selectedMarket?.priceDecimalPrecision) : "..."}
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.subtitle}>
                        <Icon name="clock" size="16" />
                        Week Change
                    </div>
                    <div className={styles.content} style={{ color: parseFloat(selectedMarket?.weekChange) > 0 ? '#00C076' : '#FF6838' }}>
                        {Object.keys(selectedMarket).length ? getDigitsAfterDecimal(selectedMarket?.weekChange, selectedMarket?.priceDecimalPrecision) : "..."}
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.subtitle}>
                        <Icon name="chart" size="16" />
                        Volume
                    </div>
                    <div className={styles.content}>
                        {Object.keys(selectedMarket).length ? selectedMarket?.volume : ""}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Main;