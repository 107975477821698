import React, { useEffect, useState } from "react";
import styles from "./OpenOrdersList.module.sass";
import Modal from "../../components/Modal";
import Withdraw from "../../components/Withdraw";
import Funds from "./Funds";
import Order from "../../components/Order";
import Main from "./Main";
import requestHandler from "../../actions/httpClient";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { socketDataReceived } from "../../actions/markets";
import LoaderScreen from "../../components/LoaderScreen";
import Successfully from "./Successfully";
import { addNotification } from "../../components/Notification";

const OpenOrdersList = () => {
  const [visibleWithdraw, setVisibleWithdraw] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageno, setPageno] = useState(1);
  const [allOrders, setAllOrders] = useState();
  const dispatch = useDispatch();
  const currencyOptions = [
    { cId: "all", cValue: "All" },
    { cId: parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER), cValue: "Buy" },
    { cId: parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER), cValue: "Sell" }
  ];
  const [currencyText, setCurrencyText] = useState(currencyOptions[0]?.cValue);
  const [currencyValue, setCurrencyValue] = useState(currencyOptions[0]?.cId);
  const [markets, setMarkets] = useState([]);
  const [marketValue, setMarketValue] = useState("All");
  const { socketMarketData } = useSelector(state => state.markets);
  const [visibleSuccessfully, setVisibleSuccessfully] = useState(false);
  const [cancelledItem, setCancelledItem] = useState([]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      autoConnect: false,
      transports: ['websocket']
    });
    let marketSocket = "new_europe_markets_web";
    socket.connect();
    const marketData = "europe_markets";

    socket.emit("marketData", marketData);

    socket.on(marketSocket, (markets) => {
      dispatch(socketDataReceived(markets));
      setLoading(false);
      socket.disconnect();
    });

    const interval = setInterval(() => {
      socket.connect()
      socket.emit("marketData", marketData);
    }, parseInt(process.env.REACT_APP_MARKET_SOCKET_TIME_INTERVAL));

    return () => {
      clearInterval(interval);
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    setPageno(1);
  }, [currencyValue, marketValue]);

  useEffect(() => {
    if (socketMarketData?.length > 0) {
      const markets = socketMarketData?.[0]?.markets?.map(x => x.slug);
      setMarkets(["All", ...markets]);
    }
  }, [socketMarketData]);

  useEffect(() => {
    getOpenOrders(pageno);
  }, [pageno, marketValue, currencyText]);

  const handleLoadMore = () => {
    setPageno(pageno + 1);
  };

  const getOpenOrders = async (page) => {
    setLoading(true);
    let data = {
      page: page,
      signature: localStorage.getItem("signature"),
    };
    if (currencyValue != 'all') {
      data.direction = currencyValue
    };
    if (marketValue?.toLowerCase() !== "all") {
      data.slug = marketValue
    };
    try {
      const getAllOpenOrdersPayload = await requestHandler('getAllOpenOrders', 'post', data, 'jwt_token');
      setLoading(false);
      if (page === 1) {
        setAllOrders(getAllOpenOrdersPayload?.data?.data);
      }
      else {
        setAllOrders(prevOrders => [
          ...prevOrders,
          ...getAllOpenOrdersPayload?.data?.data
        ]);
      };
    }
    catch (e) {
      setLoading(false);
    };
  };

  const cancelOpenOrder = async (orderId) => {
    setLoading(true);
    const data = {
      signature: localStorage.getItem("signature"),
      orderId: orderId
    }
    try {
      const response = await requestHandler('cancelOrder', 'post', data, 'jwt_token');
      setLoading(false);
      if (response?.status == 200) {
        addNotification({
          title: 'Success',
          message: 'Order cancelled successfully',
          type: 'success'
        })
        setVisibleSuccessfully(false);
        getOpenOrders(pageno);
      }
    }
    catch (error) {
      setLoading(false);
      console.log("error", error);
      addNotification({
        title: 'Error',
        message: 'Something went wrong.',
        type: 'danger',
      })
      setVisibleSuccessfully(false);
    };
  };

  return (
    <>
      <Order >
        <Main />
        {loading && <LoaderScreen />}
        <div className={styles.list}>
          <div className={styles.item}>
            <div className={styles.head}>Open Orders</div>
            <div className={styles.body}>
              <Funds
                allOrders={allOrders}
                handleLoadMore={handleLoadMore}
                pageno={pageno}
                loading={loading}
                currencyOptions={currencyOptions}
                currencyValue={currencyValue}
                setCurrencyValue={setCurrencyValue}
                marketValue={marketValue}
                setMarketValue={setMarketValue}
                markets={markets}
                currencyText={currencyText}
                setCurrencyText={setCurrencyText}
                setVisibleSuccessfully={setVisibleSuccessfully}
                setCancelledItem={setCancelledItem}
              />
            </div>
          </div>
        </div>
      </Order>
      <Modal
        visible={visibleSuccessfully}
        onClose={() => setVisibleSuccessfully(false)}
      >
        <Successfully
          item={cancelledItem}
          closeModal={() => setVisibleSuccessfully(false)}
          cancelOpenOrder={cancelOpenOrder}
        />
      </Modal>
    </>
  );
};

export default OpenOrdersList;
