import { updateChart, _subs } from "./stream";
const socket = new WebSocket(process.env.REACT_APP_API_URL_CHARTS);

let history = {};
var _subChannel = [];

export default {
  history: history,
  async getBars(symbolInfo, resolution, endTime, first) {
    try {
      const symbolName = symbolInfo.name.replace(/-/g, '_');
      socket.onopen = () => {
        _subChannel[0] = {
          "symbol_name": symbolName,
          "resolution": resolution
        }
        socket.send(JSON.stringify({
          action: 'subscribe',
          channel: symbolName,
          'resolution': resolution,
          'endTime': endTime
        }));
      };
      socket.onclose = () => {
        socket.send(JSON.stringify({
          action: 'unsubscribe',
          channel: _subChannel[0].symbol_name + '_' + _subChannel[0].resolution
        }));
      };
      socket.onerror = (error) => {
        socket.send(JSON.stringify({
          action: 'unsubscribe',
          channel: _subChannel[0].symbol_name + '_' + _subChannel[0].resolution
        }));
      };
      if (socket.readyState === WebSocket.OPEN) {
        if (_subChannel.length > 0) {
          socket.send(JSON.stringify({
            action: 'unsubscribe',
            channel: _subChannel[0].symbol_name + '_' + _subChannel[0].resolution
          }));
        }
        _subChannel[0] = {
          "symbol_name": symbolName,
          "resolution": resolution
        }
        socket.send(JSON.stringify({
          action: 'subscribe',
          channel: symbolName,
          'resolution': resolution,
          'endTime': endTime
        }));
      }
      return new Promise((resolve, reject) => {
        socket.onmessage = (event) => {
          const barsData = JSON.parse(event.data)
          if (barsData.message.type == 'live') {
            if (_subs[0]?.symbolInfo?.name?.toLowerCase() == barsData?.message?.slug?.toLowerCase()) {
              var data = {
                ts: barsData.message.data.time,
                price: barsData.message.data.close,
                total: barsData.message.data.volume,
                low: parseFloat(barsData.message.data.low),
                high: parseFloat(barsData.message.data.high),
                open: parseFloat(barsData.message.data.open),
                close: barsData.message.data.close,
              }
              updateChart(data)
            }
            return;
          }
          if (barsData && barsData.message.s === 'no_data') {
            resolve([]);
            return;
          }
          if (barsData.message.data.length) {
            const myBars = barsData.message.data;
            let bars = myBars.map((el) => ({
              time: el.time * 1000,
              low: parseFloat(el.low),
              high: parseFloat(el.high),
              open: parseFloat(el.open),
              close: parseFloat(el.close),
              volume: parseFloat(el.volume),
            }));
            if (first) {
              const lastBar = bars[bars.length - 1];
              history[symbolInfo.name] = { lastBar };
            }
            resolve(bars);
          }
        };
      });
    } catch (error) {
      return ([]);
    }
  },
  async socketFunction() {
    return {
      "socket": socket,
      "subChannel": _subChannel
    }
  }
};